.rich-text {
    $_arrow-padding: 24px;
    $_transition-feel: 250ms ease-out;

    @include block-bottom-margin;

    a {
        padding-left: $_arrow-padding;
        background: url("#{$static-path}/svgs/icon-arrow-right-primary-color.svg") no-repeat;
        background-position: 2px calc(50% + 1px);
        background-size: 16px 14px;
        color: $primary;
        text-decoration: none;
        transition: background-position $_transition-feel;

        span {
            display: inline-block;
            background-color: $white;
            transform: translateX(0);
            transition: transform $_transition-feel;
        }

        &:hover {
            background-position: calc(100% - 2px) calc(50% + 1px);
            text-decoration: underline;

            span {
                text-decoration: underline;
                transform: translateX(-#{$_arrow-padding});
            }
        }
    }

    &--bg-light a span {
        background-color: $bg-light;
    }

    &--bg-light-alt a span {
        background-color: $bg-light-alt;
    }

    ol {
        padding-left: spacing(4);
    }

    ul {
        position: relative;
        padding-left: spacing(4);
        list-style: none;

        li {
            &::before {
                content: "●";
                position: absolute;
                left: 3px;
                color: $primary;
                font-size: 9px;
                transform: translateY(9px);
            }
        }
    }

    & > :last-child {
        margin-bottom: 0 !important;
    }
}
