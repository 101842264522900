// Use this file to override all bootstrap default variables, all variables
// which can be overridden here are listed in this file:
// https://raw.githubusercontent.com/twbs/bootstrap/v5.1.3/scss/_variables.scss

// Colors
//
$_spring-orange: #c30;
$_spring-blue: #193495;

$text-color: #2f2f2f;
$bg-light: #f0f3ff;
$bg-light-alt: #fff1e1;

$gray-100: #efefef;
$gray-200: #ddd;
$gray-300: #d8d8d8;

$headings-color: $_spring-blue;
$primary: $_spring-orange;
$secondary: $_spring-blue;
// $success:       $green;
// $info:          $cyan;
// $warning:       $yellow;
$danger: $primary;
// $light:         $gray-100;
// $dark:          $gray-900;
$focus-color: #015fcc;

$active-gradient-start: #9f0400;
$active-gradient-end: #c30;

// Body
//
$body-color: $text-color;

// Spacing
//
$spacer: 1rem;
// prettier-ignore
$spacers: (
    0: 0,
    1: $spacer * 0.25, //   4px
    2: $spacer * 0.5,  //   8px
    3: $spacer,        //  16px
    4: $spacer * 1.5,  //  24px
    5: $spacer * 2,    //  32px
    6: $spacer * 3,    //  48px
    7: $spacer * 4,    //  64px
    8: $spacer * 6,    //  96px
    9: $spacer * 8,    // 128px
);

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1600px,
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1560px,
);

// Typography
// Outfit is the main font, Mulish is for latin-extended, and Noto is for Chinese.
$font-family-base: outfit, mulish, "Noto Sans SC", "Helvetica Neue", arial, sans-serif;
$font-weight-bold: 600;
$headings-font-weight: 700;
$small-font-size: 0.875rem;

// Components
//
$border-radius: 0.5rem;

// Forms
//
$enable-validation-icons: false;
