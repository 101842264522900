.header {
    position: absolute;
    z-index: $zindex-fixed;
    width: 100%;
    transform: translateY(0);
    pointer-events: none;
    background-color: transparent;
    transition-property: transform;
    transition-duration: var(--default-animation-speed);
    transition-timing-function: ease-out;

    @include media-breakpoint-up(sm) {
        top: $header-top-sm;
    }

    @include media-breakpoint-up(lg) {
        --bs-gutter-x: #{spacing(5)};

        top: $header-top-lg;
    }

    .is-scrolling-up &,
    .is-scrolled & {
        position: fixed;
        transform: translateY(0);
        background-color: $white;
        box-shadow: rgb(0 0 0 / 8%) 0 24px 64px 0;

        @include media-breakpoint-up(sm) {
            top: 0;
        }
    }

    .is-scrolled:not(.is-scrolling-up) &:not(:hover) {
        transform: translateY(-100%);
        transition-duration: 0ms;
    }
}

.header-container {
    @extend .container;

    position: relative;

    .is-scrolling-up &,
    .is-scrolled & {
        position: static;
    }
}

.header-layout {
    display: flex;
    align-items: center;
    overflow: visible;
    height: $header-height-xs;
    padding: 0 spacing(3);
    margin: 0 -#{$grid-gutter-half-width};
    background-color: $white;
    pointer-events: auto;
    box-shadow: rgb(0 0 0 / 8%) 0 24px 64px 0;

    @include media-breakpoint-up(sm) {
        height: $header-height-sm;
        border: 1px solid transparent;
        border-radius: $border-radius;
    }

    @include media-breakpoint-up(lg) {
        height: $header-height-lg;
        margin: 0;
    }

    .is-scrolling-up &,
    .is-scrolled & {
        box-shadow: none;
    }
}

// Header flex-items
.header__nav-mobile {
    @include fouc-display-block;

    margin-right: spacing(2);
    color: $headings-color;

    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.header__home-link {
    margin-right: spacing(3);
}

.header__logo {
    width: 110px;
    height: 40px;

    @include media-breakpoint-up(md) {
        width: 150px;
        height: 54px;
    }

    @include media-breakpoint-up(lg) {
        width: 172px;
        height: 65px;
    }
}

.header__nav-desktop {
    flex: 1 0 auto;

    @include media-breakpoint-up(lg) {
        @include fouc-display-block;
    }
}

.header__user-actions {
    display: flex;
    gap: spacing(2);
    margin-left: auto;

    .icon-button {
        color: $headings-color;

        &:hover {
            color: $primary;
        }
    }

    .header-search-button svg {
        transform: scale(1.2);
    }
}

.header__contact {
    @include media-breakpoint-up(lg) {
        margin-left: spacing(3);
        @include fouc-display-block;
    }
}
