//-- Buttons that open the language select
.language-select-wide {
    display: none;

    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        gap: spacing(2);
        padding: 0.5rem 10px;
        border: none;
        background: $white;
        color: $secondary;
        font-size: 14px;
        font-weight: $font-weight-bold;
        border-radius: 1.5rem;

        & > * {
            transition: transform var(--default-animation-speed) ease-in-out;
        }

        &:hover {
            .language-select__flag {
                transform: translateX(30px);
            }

            .language-select__code {
                transform: translateX(-30px);
            }
        }
    }
}

.header__language-select {
    @extend .language-select-wide;

    @include media-breakpoint-up(lg) {
        position: absolute;
        top: -3.5rem;
        right: 0;
        margin-right: 0.75rem;
    }

    @media (min-width: #{map-get($grid-breakpoints, 'lg')}) and (max-height: 800px) {
        top: -2.75rem;
    }

    @media (min-width: #{map-get($grid-breakpoints, 'lg')}) and (max-height: 666px) {
        top: auto;
        bottom: -3.5rem;
    }

    body.is-scrolled & {
        display: none;
    }
}

.nav-mobile__language-select {
    display: flex;
    align-items: center;
    padding: spacing(1) spacing(2);
    border: 2px solid $secondary;
    margin-top: auto;
    background: $white;
    color: $secondary;
    font-weight: $font-weight-bold;
    text-align: left;
    gap: spacing(2);
    border-radius: 1.5rem;

    &__arrow {
        margin: 0 spacing(2) 0 auto;
    }

    @include media-breakpoint-up(md) {
        padding: spacing(2);
        gap: 0.75rem;
    }
}

.footer-narrow__language-select {
    @extend .nav-mobile__language-select;

    margin-bottom: spacing(5);

    &__arrow {
        margin: 0 spacing(2) 0 auto;
    }

    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.footer-wide__language-select {
    @extend .language-select-wide;

    border: 1px solid $border-color;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
}

//-- Shared

.language-select__flag {
    width: 2rem;
    height: 2rem;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 50%;

    @include media-breakpoint-down(md) {
        .nav-mobile__language-select & {
            width: 20px;
            height: 20px;
        }
    }

    @include media-breakpoint-up(lg) {
        .header__language-select &,
        .footer-wide__language-select & {
            width: 24px;
            height: 24px;
        }
    }

    &.flag-bg-be {
        background-image: url("#{$static-path}/svgs/flags/be.svg");
    }

    &.flag-bg-ca {
        background-image: url("#{$static-path}/svgs/flags/ca.svg");
    }

    &.flag-bg-ch {
        background-image: url("#{$static-path}/svgs/flags/ch.svg");
    }

    &.flag-bg-cz {
        background-image: url("#{$static-path}/svgs/flags/cz.svg");
    }

    &.flag-bg-de {
        background-image: url("#{$static-path}/svgs/flags/de.svg");
    }

    &.flag-bg-en {
        background-image: url("#{$static-path}/svgs/flags/en.svg");
    }

    &.flag-bg-es {
        background-image: url("#{$static-path}/svgs/flags/es.svg");
    }

    &.flag-bg-fr {
        background-image: url("#{$static-path}/svgs/flags/fr.svg");
    }

    &.flag-bg-gb {
        background-image: url("#{$static-path}/svgs/flags/gb.svg");
    }

    &.flag-bg-hk,
    &.flag-bg-zh {
        background-image: url("#{$static-path}/svgs/flags/hk.svg");
    }

    &.flag-bg-hu {
        background-image: url("#{$static-path}/svgs/flags/hu.svg");
    }

    &.flag-bg-it {
        background-image: url("#{$static-path}/svgs/flags/it.svg");
    }

    &.flag-bg-pl {
        background-image: url("#{$static-path}/svgs/flags/pl.svg");
    }

    &.flag-bg-nl {
        background-image: url("#{$static-path}/svgs/flags/nl.svg");
    }
}

//-- Language select modal

.language-select {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    background: $bg-light;
}

.language-select-container {
    @extend .container;

    position: relative;
    padding-top: spacing(3);
}

.language-select__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.language-select__title {
    flex: 1 0;
    margin-right: spacing(5);
    margin-bottom: spacing(4);
}

.language-select__close {
    margin-top: spacing(2);
    background-color: $bg-light;
}

.language-select__current {
    flex: 0 0 100%;
    margin-bottom: spacing(6);
    color: $secondary;
    font-weight: $font-weight-bold;

    .language-select__flag {
        float: left;
        margin-right: spacing(2);
        transform: translateY(-1px);
    }
}

.language-select__country {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: spacing(4) 0;
    border-top: 1px solid $border-color;

    &:last-of-type {
        border-bottom: 1px solid $border-color;
    }
}

.language-select__country-name {
    @extend .h4;

    display: flex;
    align-items: center;
    gap: spacing(2);
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
        gap: spacing(3);

        .language-select__flag {
            width: spacing(6);
            height: spacing(6);
        }
    }
}

.language-select__language {
    margin-bottom: spacing(4);

    &:last-of-type {
        margin: 0;
    }
}

@include media-breakpoint-up(xl) {
    .language-select__languages {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-left: 5rem;
        gap: spacing(3);
    }

    .language-select__language {
        margin-bottom: 0;
    }
}

.language-select__image {
    width: 100%;
    height: 150px;
    margin-top: spacing(7);
    background: url("#{$static-path}/images/earth-half.webp") no-repeat;
    background-position: center top;
    background-size: 140%;
}

@include media-breakpoint-up(md) {
    .language-select__close {
        margin-top: spacing(3);
    }

    .language-select__image {
        height: 300px;
        background-size: 120%;
    }
}

@include media-breakpoint-up(lg) {
    .language-select {
        background: $bg-light url("#{$static-path}/images/earth.webp") no-repeat;
        background-position: 70vw 128px;
        background-size: 960px;
    }

    .language-select__header,
    .language-select__current,
    .language-select__form {
        width: min(60%, 920px);
    }

    .language-select__close {
        position: absolute;
        top: 0;
        right: calc(var(--bs-gutter-x) * 0.5);
    }

    .language-select__image {
        display: none;
    }
}

@media (min-width: 2100px) {
    .language-select {
        background-position: 65vw 128px;
    }
}

@include media-breakpoint-up(xxl) {
    .language-select__image {
        width: 33vw;
    }
}
