$static-path: "/static/spring";

// Header
$header-top-sm: 40px;
$header-top-lg: 7vh;
$header-height-xs: 64px;
$header-height-sm: 88px;
$header-height-lg: 104px;
$header-desktop-menu-anim-duration: 200ms;
$header-desktop-menu-delay-out: 100ms;

// Miscellaneous
$icon-button-wh: 2rem;
$outline-padding: 3px;

// CSS variables we need in JS
:root {
    --header-desktop-menu-anim-duration: #{$header-desktop-menu-anim-duration};
    --header-search-transform-origin-x: 87%;
    --default-animation-speed: 200ms;
}
