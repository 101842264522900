.image-responsive {
    position: relative;
    text-align: center;

    & p {
        max-width: 800px;
        margin: 0 auto 2rem;
    }

    & img {
        width: 100%;
        height: auto;

        @include media-breakpoint-down(lg) {
            right: 50%;
            left: 50%;
            width: 100vw;
            margin-right: -50vw;
            margin-left: -50vw;
        }
    }

    &__body {
        @extend .container;
    }

    &__buttons {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 1rem;

        @include media-breakpoint-up(md) {
            bottom: 1rem;
        }
    }
}
