@import "wizard";

.wizard-getintouch {
    min-height: 100vh;

    &.wizard--complete {
        .wizard-footer {
            display: none;
        }
    }

    [type="checkbox"] {
        outline: unset !important;
    }
}

.checkbox-block {
    $box-shadow-small: 0 2px 4px 0 rgb(0 0 0 / 8%);
    $box-shadow-large: 0 16px 40px 0 rgb(0 0 0 / 8%);

    position: relative;

    &__label {
        position: relative;
        height: 100%;
        padding: spacing(4);
        border: 1px solid $border-color;
        background-color: $white;
        color: $secondary;
        border-radius: $border-radius;
        box-shadow: $box-shadow-small, $box-shadow-large;

        &::before {
            content: "";
            position: absolute;
            top: spacing(2);
            right: spacing(2);
            width: 28px;
            height: 28px;
            border: 2px solid $border-color;
            background-color: $gray-100;
            box-shadow: inset 1px 1px 4px 0 rgb(0 0 0 / 8%);
            border-radius: $border-radius;
        }

        &:is(a) {
            &:hover {
                color: #c30;
                border-color: currentcolor;
                box-shadow:
                    inset 0 0 0 1px #c30,
                    0 2px 4px 0 rgb(0 0 0 / 8%),
                    0 16px 40px 0 rgb(0 0 0 / 8%);
            }

            &::before {
                content: normal;
            }
        }

        [type="checkbox"]:focus-visible + & {
            @include focus-outline;
        }

        [type="checkbox"]:checked + & {
            color: $primary;
            border-color: $primary;
            box-shadow:
                inset 0 0 0 1px $primary,
                $box-shadow-small,
                $box-shadow-large;

            &::before {
                background-color: $primary;
                border-color: $primary;
                box-shadow: unset;
            }

            &::after {
                content: "";
                position: absolute;
                top: spacing(2);
                right: spacing(2);
                width: 28px;
                height: 28px;
                background: url("#{$static-path}/svgs/icon-checkbox-check-white.svg")
                    no-repeat;
                background-position: center 5px;
                background-size: 16px;
            }
        }
    }
}

// Each "step" of wizard-getintouch
[class^="wizard-getintouch"] {
    display: flex;
    flex-direction: column;
    gap: spacing(2);

    input[type="checkbox"] {
        position: absolute;
        appearance: none;
    }

    @media (min-width: map-get($grid-breakpoints, sm)) and (min-height: 500px) {
        gap: spacing(3);
    }

    @media (min-width: map-get($grid-breakpoints, lg)) and (min-height: 500px) {
        gap: spacing(3) spacing(5);
    }

    @media (min-width: map-get($grid-breakpoints, xl)) and (min-height: 500px) {
        gap: spacing(3) spacing(7);
    }
}

.wizard-getintouch-help {
    display: grid;
    gap: spacing(2);
    grid-auto-rows: 1fr;

    @media (min-width: map-get($grid-breakpoints, sm)) and (min-height: 500px) {
        gap: spacing(3);
    }

    @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    }

    &__choice {
        display: flex;
        align-items: center;
        gap: spacing(3);
        padding-right: spacing(6);

        svg {
            width: 55px;
            height: auto;
            aspect-ratio: 1;
        }

        strong {
            display: block;
            flex: 1 0;
        }

        @media (min-width: map-get($grid-breakpoints, md)) and (min-height: 500px) {
            gap: spacing(4);

            svg {
                width: 88px;
            }
        }

        @media (min-width: map-get($grid-breakpoints, lg)) and (min-height: 500px) {
            flex-direction: column;
            padding: spacing(7) spacing(5) spacing(5);
            text-align: center;
        }
    }
}

.wizard-getintouch-ship {
    &__choice {
        width: 100%;
        padding: spacing(3) spacing(6) spacing(3) spacing(3);
    }

    @include media-breakpoint-up(md) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .checkbox-block {
            flex: 0 0 50%;
        }
    }

    @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.wizard-getintouch-items {
    & .form-field {
        max-width: 320px;
        margin: 0 auto;
    }
}

.wizard-getintouch-details {
    display: block;
    max-width: 780px;
    padding: spacing(5) spacing(4);
    border: 1px solid $border-color;
    margin-right: auto;
    margin-left: auto;
    background-color: $white;

    & > .form-field:last-of-type {
        margin-bottom: 0;
    }

    & > .wizard-getintouch-details__disclaimer {
        margin-bottom: 0;

        @include media-breakpoint-up(md) {
            padding-top: spacing(2);
        }

        @include media-breakpoint-up(lg) {
            padding-top: spacing(3);
        }
    }
}
