.contact {
    max-width: 780px;
    margin: 0 auto;
}

.contact-form-section {
    h3:not(:first-of-type) {
        margin-top: spacing(5);

        @include media-breakpoint-up(md) {
            margin-top: spacing(6);
        }

        @include media-breakpoint-up(lg) {
            margin-top: spacing(7);
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        gap: spacing(3);

        @include media-breakpoint-up(md) {
            flex-direction: row;
            align-items: center;

            & > p {
                margin: 0;
            }
        }
    }
}

// Specificity issue with .rich-text
.contact__disclaimer.contact__disclaimer {
    margin-top: spacing(4);

    @include media-breakpoint-up(md) {
        margin-top: spacing(5);
        margin-bottom: spacing(5);
    }
}

.contact-feedback-section {
    padding: spacing(7) 0;
    text-align: center;

    @include media-breakpoint-up(md) {
        padding: spacing(8) 0;
    }

    @include media-breakpoint-up(xl) {
        padding: spacing(9) 0;
    }
}
