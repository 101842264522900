// Styling for solution-teaser & solution-overview
.solution-teaser__button {
    margin-top: spacing(5);
    text-align: center;

    @include media-breakpoint-only(xs) {
        & > .btn {
            width: 100%;
        }
    }

    @include media-breakpoint-up(md) {
        margin-top: spacing(6);
    }
}

.solution-block {
    .boxed-block__body {
        min-height: 6.5rem; // Make space for arrow
    }

    &__arrow {
        @extend .circle-arrow;

        position: absolute;
        right: spacing(3);
        bottom: spacing(3);
        color: $primary;
    }

    &:hover {
        color: $text-color;

        .solution-block__arrow {
            @extend .circle-arrow-hover;

            color: $white;
        }
    }
}
