// Purposely different from button-list block
.buttons-centered {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    @include block-bottom-margin;
}

.btn {
    display: inline-block;
    width: 100%;
    padding: spacing(2) 0;
    font-weight: $font-weight-bold;
    text-decoration: none;
    border-radius: spacing(6);

    @include media-breakpoint-up(sm) {
        width: auto;
        padding: spacing(3) spacing(4);
    }

    @include media-breakpoint-up(xl) {
        padding: spacing(3) calc(spacing(4) + 0.5rem);
    }
}

.btn-sm {
    @include media-breakpoint-up(sm) {
        padding: spacing(2) spacing(3);
    }
}

.btn-primary {
    color: $white;
    border-color: transparent;

    &:focus {
        @include focus-outline;

        background-color: $primary;
        color: $white;
        border-color: transparent;
        box-shadow: none;
    }

    &:hover,
    &:active {
        background-color: $primary;
        background-image: linear-gradient(90deg, $active-gradient-start, $active-gradient-end);
        color: $white;
        box-shadow: rgb(0 0 0 / 8%) 0 16px 24px 0;
    }
}

.btn-secondary {
    border: 2px solid $primary;
    background-color: transparent;
    color: $primary;

    &:focus {
        @include focus-outline;

        background-color: $white;
        color: $primary;
        border-color: $primary;
        box-shadow: none;
    }

    &:hover,
    &:active {
        background-color: $primary;
        background-image: linear-gradient(90deg, $active-gradient-start, $active-gradient-end);
        color: $white;
        border-color: $active-gradient-end;
        box-shadow: rgb(0 0 0 / 8%) 0 16px 24px 0;
    }
}

.btn-tertiary {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: none;
    color: $primary;
    gap: 0.5rem;

    .btn-tertiary__icon {
        @extend .circle-arrow;

        pointer-events: none;
    }

    &:focus {
        @include focus-outline;

        background-color: transparent;
        color: $primary;
        box-shadow: none;
    }

    &:hover,
    &:active {
        color: $primary;

        .btn-tertiary__icon {
            @extend .circle-arrow-hover;
        }
    }
}

.btn-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border: 2px solid $secondary;
    background-color: transparent;
    color: $secondary;
    border-radius: 50%;
    transition: all 0.3s ease;
    opacity: 1;

    &:hover:not(:disabled, .disabled) {
        background-color: $secondary;
        color: $white;
    }

    &:disabled,
    &.disabled {
        opacity: 0.3;
    }

    @include media-breakpoint-up(md) {
        width: 72px;
        height: 72px;
    }
}
