// Used for eg:
// - tab list
// - content pages
// - pricing

.squiffy-bg-component {
    $spacing-y-xs: spacing(4);
    $spacing-y-md: spacing(5);
    $spacing-y-lg: spacing(6);
    $spacing-y-xl: spacing(8);
    $spacing-x-xs: spacing(4);
    $spacing-x-md: spacing(5);
    $spacing-x-lg: spacing(7);
    $spacing-x-xl: spacing(8);

    position: relative;
    padding: $spacing-y-xs 0;

    @include media-breakpoint-up(md) {
        padding: $spacing-y-md 0;
    }

    @include media-breakpoint-up(lg) {
        padding: $spacing-y-lg 0;
    }

    &::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        border-radius: $border-radius 0 0 $border-radius;
        width: 100%;
        height: 100%;
    }

    &.align-right {
        &::before {
            left: spacing(6); // container padding + body padding

            @include media-breakpoint-up(md) {
                left: spacing(7);
            }

            @include media-breakpoint-up(lg) {
                left: spacing(8);
                margin-left: calc((100% - #{map-get($container-max-widths, lg)}) / 2);
            }

            @include media-breakpoint-up(xl) {
                left: spacing(9);
                margin-left: calc((100% - #{map-get($container-max-widths, xl)}) / 2);
            }

            @include media-breakpoint-up(xxl) {
                margin-left: calc((100% - #{map-get($container-max-widths, xxl)}) / 2);
            }
        }

        .squiffy-bg-component__header {
            padding-left: $spacing-x-xs;
            margin: 0 0 $spacing-y-xs spacing(4);

            @include media-breakpoint-up(md) {
                padding-left: $spacing-x-md;
                margin: 0 0 $spacing-y-md spacing(5);
            }

            @include media-breakpoint-up(lg) {
                padding-left: $spacing-x-lg;
                margin: 0 0 $spacing-y-lg spacing(7);
            }

            @include media-breakpoint-up(xl) {
                padding-left: $spacing-x-xl;
                margin-left: $spacing-x-xl;
            }
        }
    }

    &.align-left {
        &::before {
            right: spacing(6); // container padding + body padding

            @include media-breakpoint-up(md) {
                right: spacing(7);
            }

            @include media-breakpoint-up(lg) {
                right: spacing(8);
                margin-right: calc((100% - #{map-get($container-max-widths, lg)}) / 2);
            }

            @include media-breakpoint-up(xl) {
                right: spacing(9);
                margin-right: calc((100% - #{map-get($container-max-widths, xl)}) / 2);
            }

            @include media-breakpoint-up(xxl) {
                margin-right: calc((100% - #{map-get($container-max-widths, xxl)}) / 2);
            }
        }

        .squiffy-bg-component__header {
            padding-right: $spacing-x-xs;
            margin: 0 spacing(4) $spacing-y-xs 0;

            @include media-breakpoint-up(md) {
                padding-right: $spacing-x-md;
                margin: 0 spacing(5) $spacing-y-md 0;
            }

            @include media-breakpoint-up(lg) {
                padding-right: $spacing-x-lg;
                margin: 0 spacing(7) $spacing-y-lg 0;
            }

            @include media-breakpoint-up(xl) {
                padding-right: $spacing-x-xl;
                margin-right: $spacing-x-xl;
            }
        }
    }

    &.light-blue::before {
        background-color: $bg-light;
    }

    &.light-orange::before {
        background-color: $bg-light-alt;
    }

    &__container {
        width: 100%;
        padding: 0 spacing(4);

        @include media-breakpoint-up(md) {
            padding: 0 spacing(5);
        }

        @include media-breakpoint-up(lg) {
            max-width: #{map-get($container-max-widths, lg)};
            margin: 0 auto;
        }

        @include media-breakpoint-up(xl) {
            max-width: #{map-get($container-max-widths, xl)};
        }

        @include media-breakpoint-up(xxl) {
            max-width: #{map-get($container-max-widths, xxl)};
        }
    }

    &__footer {
        padding-left: $spacing-x-xs;
        margin: $spacing-y-xs 0 0 spacing(4);

        @include media-breakpoint-up(md) {
            padding-left: $spacing-x-md;
            margin: $spacing-y-md 0 0 spacing(5);
        }

        @include media-breakpoint-up(lg) {
            padding-left: $spacing-x-lg;
            margin: $spacing-y-lg 0 0 spacing(7);
        }

        @include media-breakpoint-up(xl) {
            padding-left: $spacing-x-xl;
            margin-left: $spacing-x-xl;
        }
    }

    &__body {
        padding: 0 $spacing-x-xs;
        border-radius: $border-radius;
        background-color: $white;
        box-shadow:
            0 24px 80px rgb(0 0 0 / 8%),
            0 2px 8px rgb(0 0 0 / 7.72%);

        @include media-breakpoint-up(md) {
            padding: 0 $spacing-x-md;
        }

        @include media-breakpoint-up(lg) {
            padding: 0 $spacing-x-lg;
        }

        @include media-breakpoint-up(xl) {
            padding: 0 $spacing-x-xl;
        }
    }
}
