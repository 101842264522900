/* stylelint-disable indentation */

.simple-select {
    --ss-primary-color: #{$primary};
    --ss-bg-color: #fff;
    --ss-font-color: #000;
    --ss-font-placeholder-color: #8d8d8d;
    --ss-disabled-color: #dcdee2;
    --ss-border-color: #ced4da;
    --ss-highlight-color: #fffb8c;
    --ss-success-color: #00b755;
    --ss-error-color: #dc3545;
    --ss-focus-color: #{$primary};
    --ss-main-height: 2.25rem;
    --ss-content-height: 20rem;
    --ss-spacing-inline-l: 0.8rem;
    --ss-spacing-block-l: 0.8rem;
    --ss-spacing-inline-m: 0.6rem;
    --ss-spacing-block-m: 0.6rem;
    --ss-spacing-inline-s: 0.4rem;
    --ss-spacing-block-s: 0.4rem;
    --ss-animation-timing: 0.2s;
    --ss-border-radius: 0.5rem;
    --ss-box-shadow: inset 2px 2px 4px 0 rgb(0 0 0 / 8%), inset 0 0 0 30px white;
    --ss-border-width: 2px;
    --ss-font-size: 1.1rem;

    display: flex;
    flex-wrap: wrap;
    min-width: 12rem;
    gap: 0.2rem;

    @include media-breakpoint-up(md) {
        --ss-main-height: 3.188rem;

        min-width: 20rem;
    }
}

.single-selected-items {
    display: flex;
    border-top: 1px solid $gray-300;
}

.single-select-component {
    display: none;
}

.ss-content.single-select-component {
    --ss-primary-color: #{$primary};
    --ss-bg-color: #fff;
    --ss-font-color: #000;
    --ss-font-placeholder-color: #8d8d8d;
    --ss-disabled-color: #dcdee2;
    --ss-border-color: #ced4da;
    --ss-highlight-color: #fffb8c;
    --ss-success-color: #00b755;
    --ss-error-color: #dc3545;
    --ss-focus-color: #{$primary};
    --ss-main-height: 2.25rem;
    --ss-content-height: 20rem;
    --ss-border-radius: 0.5rem;
    --ss-border-width: 2px;
    --ss-spacing-inline-l: 0.8rem;
    --ss-spacing-block-l: 0.8rem;
    --ss-spacing-inline-m: 0.6rem;
    --ss-spacing-block-m: 0.6rem;
    --ss-spacing-inline-s: 0.4rem;
    --ss-spacing-block-s: 0.4rem;
    --ss-font-size: 1.1rem;
}

.ss-main.single-select-component.ss-open-below,
.ss-main.single-select-component.ss-open-above,
.ss-content.single-select-component.ss-open-below,
.ss-content.single-select-component.ss-open-above {
    --ss-border-color: #015fcc;
}

.ss-main {
    &.single-select-component {
        &.is-invalid {
            --ss-border-color: #c30;
        }
    }
}

.floating-form {
    .simple-select {
        --ss-main-height: 2.5rem;
        --ss-spacing-inline-l: 0.8rem;
        --ss-spacing-block-l: 0.8rem;
        --ss-spacing-inline-m: 0.6rem;
        --ss-spacing-block-m: 0.6rem;
        --ss-spacing-inline-s: 0.375rem;
        --ss-spacing-block-s: 0.375rem;
        --ss-font-size: 1rem;
    }
}
