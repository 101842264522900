.youtube {
    &__video {
        position: relative;
        width: 100%;
        aspect-ratio: 16/9;

        iframe {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: block;
            max-width: 100%;
            border-radius: $border-radius;
        }
    }

    &__play-button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 0;
        border: none;
        background: transparent;
    }

    &__img {
        width: 100%;
        height: auto;
        border-radius: $border-radius;
        object-fit: cover;
    }

    &__play-icon {
        position: absolute;
        z-index: 1;
        padding: 24px;
        border-radius: 80px;
        background-color: $primary;
        transition: all 0.3s ease;

        &:hover {
            background-image: linear-gradient(90deg, $active-gradient-start, $active-gradient-end);
            box-shadow: rgb(0 0 0 / 8%) 0 16px 24px 0;
        }

        @include media-breakpoint-up(md) {
            padding: 40px;
        }

        @include media-breakpoint-up(lg) {
            padding: 50px;
        }

        svg {
            width: 32px;
            height: 32px;
            padding-left: 5px;

            @include media-breakpoint-up(md) {
                width: 40px;
                height: 40px;
            }

            @include media-breakpoint-up(lg) {
                width: 60px;
                height: 60px;
            }
        }
    }
}
