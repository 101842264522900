h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    @include media-breakpoint-up(lg) {
        margin-bottom: 1rem;
    }
}

h1,
.h1 {
    font-size: 2.5rem;

    @include media-breakpoint-only(md) {
        margin-bottom: 1rem;
        font-size: 3.5rem;
    }

    @include media-breakpoint-up(lg) {
        font-size: clamp(3.5rem, 3.75vw, 4.5rem);
    }
}

h2,
.h2 {
    font-size: 2rem;

    @include media-breakpoint-only(md) {
        font-size: 2.5rem;
    }

    @include media-breakpoint-up(lg) {
        font-size: clamp(2.5rem, 2.5vw, 3rem);
        line-height: 1.25;
    }
}

h3,
.h3 {
    font-size: 1.5rem;

    @include media-breakpoint-only(md) {
        font-size: 1.75rem;
    }

    @include media-breakpoint-up(lg) {
        font-size: clamp(1.75rem, 1.667vw, 2rem);
        line-height: 1.333;
    }
}

h4,
.h4 {
    font-size: 1.2rem;
    font-size: clamp(1.125rem, 1.25vw, 1.2rem);
    line-height: 1.333;
}

h5,
h6,
.h5,
.h6 {
    font-size: 1.125rem;
    font-size: clamp(1rem, 0.938vw, 1.125rem);
    line-height: 1.25;
}

body {
    font-size: 1.125rem;
    line-height: 1.555;

    @include media-breakpoint-up(lg) {
        font-size: 1.25rem;
        line-height: 1.55;
    }
}

p {
    @include media-breakpoint-up(lg) {
        margin-bottom: 1.5rem;
    }
}

.intro-text {
    font-size: calc(1rem + 2px);
    line-height: 1.333;

    @include media-breakpoint-up(lg) {
        font-size: 1.5rem;
        line-height: 1.666;
    }
}

a {
    color: $text-color;
    text-decoration: none;

    &:hover {
        color: $primary;
    }
}

.nav-link-small {
    font-size: 1rem;

    @include media-breakpoint-up(lg) {
        font-size: 1.125rem;
    }
}

*:focus-visible {
    @include focus-outline;
}

$_link-arrow-spacing: spacing(4);

.nav-arrow-link-container {
    @include media-breakpoint-up(md) {
        overflow: hidden;
        // For focus outline ↓
        padding: $outline-padding $_link-arrow-spacing $outline-padding $outline-padding;
        margin-top: -#{$outline-padding};
        margin-left: -#{$outline-padding};
    }
}

.nav-arrow-link {
    @include media-breakpoint-up(md) {
        position: relative;
        display: inline-block;
        transform: translateX(0);
        transition: transform var(--default-animation-speed) ease-out;

        &:hover {
            transform: translateX(spacing(4));
        }

        &::before {
            content: "";
            position: absolute;
            top: spacing(2);
            left: -#{$_link-arrow-spacing};
            display: block;
            width: spacing(4);
            height: spacing(3);
            background: url("#{$static-path}/svgs/icon-arrow-right-primary-color.svg") no-repeat;
            background-size: spacing(3);
        }
    }
}

.no-wrap {
    white-space: nowrap;
}
