.footer-divider {
    margin: 0;

    .primary-teaser + & {
        border: none;
        margin-top: -#{spacing(5)};

        @include media-breakpoint-up(lg) {
            margin-top: -#{spacing(7)};
        }
    }
}

.footer-top {
    padding: spacing(5) 0;

    @include media-breakpoint-up(md) {
        padding: spacing(6) 0;
    }

    @include media-breakpoint-up(lg) {
        padding: spacing(8) 0;
    }
}

.footer-bottom {
    display: flex;
    flex-direction: column;
    border-top: 1px solid $border-color;

    @include media-breakpoint-only(lg) {
        display: grid;
        grid-template-columns: auto min-content;
    }

    @include media-breakpoint-up(xl) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.footer__home__link {
    display: inline-block;
}

.footer__logo-container {
    padding-bottom: spacing(4);
    text-align: center;

    @include media-breakpoint-up(md) {
        padding-bottom: spacing(6);
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: 0;
        text-align: start;
    }
}

.footer__logo {
    width: 88px;
    height: 130px;

    @include media-breakpoint-up(md) {
        width: 104px;
        height: 144px;
    }

    @include media-breakpoint-up(lg) {
        width: 112px;
        height: 180px;
    }
}

.footer__service__links__col {
    display: none;
    margin-bottom: spacing(3);

    &[data-footer-col-id="1"] {
        display: block;
        text-align: center;
    }

    @include media-breakpoint-up(md) {
        display: block;
        margin-bottom: 0;

        &[data-footer-col-id="1"] {
            text-align: start;
        }
    }
}

.footer__service__category-link {
    color: $headings-color;
}

.footer__service__item {
    @extend .nav-arrow-link-container;

    margin-bottom: calc(spacing(2) - 3px);
}

.footer__service__link {
    @extend .nav-arrow-link;
    @extend .nav-link-small;
}

.footer__service__address {
    @extend .nav-link-small;

    text-align: center;
    white-space: nowrap;

    @include media-breakpoint-up(md) {
        text-align: start;
    }

    a[href^="tel"] {
        padding-left: calc(24px + #{spacing(1)});
        background: url("#{$static-path}/svgs/icon-telephone-primary-color.svg") no-repeat;
        background-size: 20px;

        &:hover {
            text-decoration: underline;
        }

        @include media-breakpoint-up(lg) {
            background-size: 24px;
        }
    }
}

.footer__legal {
    display: flex;
    gap: spacing(3);
    flex-flow: column wrap;
    align-items: center;
    padding: spacing(5) 0;

    @include media-breakpoint-up(md) {
        padding: spacing(6) 0 spacing(5);
    }

    @include media-breakpoint-only(lg) {
        grid-column-start: span 2;
        flex-flow: row nowrap;
        justify-content: space-between;
    }

    @include media-breakpoint-up(xl) {
        align-items: flex-start;
        padding: spacing(4) 0 spacing(5);
    }

    @include media-breakpoint-up(xxl) {
        flex-direction: row;
        align-items: center;
    }
}

.footer__legal__holding {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    color: $headings-color;
    font-weight: $font-weight-bold;

    &:hover {
        color: $headings-color;
    }

    @include media-breakpoint-up(lg) {
        margin-right: spacing(3);
    }
}

.footer__legal__logo-holding {
    width: spacing(6);
    height: spacing(6);
    margin-left: spacing(2);
}

.footer__legal__links {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    text-align: center;

    @include media-breakpoint-up(md) {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
    }
}

.footer__legal__link {
    @extend small;

    width: 50%;
    padding-bottom: spacing(3);

    &:hover {
        text-decoration: underline;
    }

    &:last-of-type {
        padding-bottom: 0;
    }

    @include media-breakpoint-up(md) {
        width: auto;
        padding-right: spacing(3);
        padding-bottom: 0;

        &:last-of-type {
            padding-right: 0;
        }
    }
}

.footer__social {
    display: flex;
    gap: spacing(3);
    flex-flow: wrap column;
    padding-bottom: spacing(5);

    @include media-breakpoint-up(md) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    @include media-breakpoint-up(lg) {
        justify-content: flex-end;
    }

    @include media-breakpoint-up(xl) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: spacing(5) 0;
    }

    @include media-breakpoint-up(xxl) {
        flex-direction: row;
        align-items: center;
    }
}

.footer__social__heading {
    margin-bottom: 0;
    text-align: center;
}

.footer__social__links {
    display: flex;
    justify-content: center;
}

.footer__social__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.75rem;
    height: 2.75rem;
    border: 2px solid $primary;
    margin-right: spacing(2);
    color: $primary;
    border-radius: 50%;

    &:last-of-type {
        margin-right: 0;
    }

    &:hover {
        background-color: $primary;
        color: $white;
        border-color: $primary;
    }

    svg {
        width: 1.5rem;
        height: 1.5rem;
    }
}

.footer__language-select {
    display: none;

    .h5 {
        margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        gap: spacing(3);
        padding-bottom: spacing(5);
        margin-left: spacing(7);
    }

    @include media-breakpoint-up(xl) {
        flex-direction: column;
        align-items: flex-start;
        padding: spacing(5) 0;
        margin-left: 0;
    }

    @include media-breakpoint-up(xxl) {
        flex-direction: row;
        align-items: center;
    }
}

.footer__newsletter-form {
    text-align: center;

    @include media-breakpoint-up(md) {
        text-align: start;
    }

    .subscribe-text-field {
        padding: 0;
        margin-top: 0;

        @include media-breakpoint-only(md) {
            flex-direction: column;
            border: none;
            background: none;
            box-shadow: none;
            gap: 1rem;

            .text-field__input {
                padding: 0.5rem 1rem;
                border: 2px solid #ced4da;
                background-color: #fff;
                background-clip: padding-box;
                color: #2f2f2f;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                appearance: none;
                border-radius: 0.25rem;
                transition:
                    border-color 0.15s ease-in-out,
                    box-shadow 0.15s ease-in-out;
                box-shadow: inset 2px 2px 4px 0 rgb(0 0 0 / 8%);
            }
        }

        @include media-breakpoint-up(lg) {
            padding: 0.5rem 0.5rem 0.5rem 0;

            .text-field__input {
                padding: 0 1rem;
            }

            .subscribe__submit {
                padding: 0.3rem 1rem;
                font-weight: 600;
            }
        }
    }
}
