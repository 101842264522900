.social-share {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: spacing(3);
    text-align: center;

    @include media-breakpoint-up(sm) {
        flex-direction: row;
    }

    @include media-breakpoint-up(lg) {
        gap: spacing(4);
    }

    &__title {
        margin: 0;
    }

    &__button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: spacing(6);
        height: spacing(6);
        border: 2px solid $primary;
        margin-right: spacing(2);
        color: $primary;
        border-radius: 50%;

        &:hover {
            border: none;
            background: linear-gradient(90deg, $active-gradient-start, $active-gradient-end);
            color: $white;
        }
    }
}
