.image-big-small {
    display: flex;
    flex-direction: column;

    &__big,
    &__small {
        display: block;
        height: auto;
        border-radius: $border-radius;
    }

    &__big {
        max-width: 85%;

        [class^="col"]:first-child & {
            margin-left: auto;
        }
    }

    &__small {
        max-width: 55%;
        margin-top: -22%;

        [class^="col"]:last-child & {
            margin-left: auto;
        }
    }
}
