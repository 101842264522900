.desktop-menu-panel {
    padding: spacing(6) spacing(6) spacing(5);
    border: 1px solid transparent;
    border-top-color: $border-color;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    background-color: $white;
    box-shadow: rgb(0 0 0 / 9%) 0 16px 16px 0;
    transform: translateY(-100%);
    transition: transform $header-desktop-menu-anim-duration ease-out $header-desktop-menu-delay-out;

    &.is-opening,
    &.is-opened {
        transform: translateY(0);
        transition: transform $header-desktop-menu-anim-duration ease-out;
    }

    &__categories {
        display: flex;
    }

    &__category {
        flex: 1 1 0;
        margin-top: spacing(6);

        &__list {
            @include list-unstyled;
        }

        &__item {
            @extend .nav-arrow-link-container;

            margin-bottom: calc(spacing(2) - 3px);
        }

        &__link {
            @extend .nav-arrow-link;
            @extend .nav-link-small;
        }
    }

    &__category-link {
        color: $headings-color;

        &:hover {
            text-decoration: underline;
        }
    }
}
