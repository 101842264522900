// Same as .form-control, but we can't @extend in media queries
@mixin as-form-control {
    width: 100%;
    padding: spacing(2) spacing(3);
    border: 2px solid #ced4da;
    background-color: #fff;
    background-clip: padding-box;
    color: #2f2f2f;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    appearance: none;
    border-radius: $input-border-radius-sm;
    transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    box-shadow: inset 2px 2px 4px 0 rgb(0 0 0 / 8%);

    @include form-control-focus;

    @include media-breakpoint-up(md) {
        font-size: 1.125rem;
    }
}

.subscribe {
    padding: spacing(5);
    background-color: $bg-light-alt;
    border-radius: $border-radius;

    &--download {
        padding: spacing(5);
        padding-inline: spacing(4);
    }

    h2 {
        margin-bottom: spacing(3);
    }

    .checkbox-list {
        margin: 0;
    }

    @include media-breakpoint-up(md) {
        padding: spacing(6);

        &--download {
            padding-block: 3.5rem;
            padding-inline: 2.5rem;
        }
    }

    @include media-breakpoint-up(xl) {
        padding: spacing(6) spacing(8);

        &--download {
            padding-block: 3.5rem;
            padding-inline: 2.5rem;
        }
    }

    @include media-breakpoint-up(xxl) {
        padding: spacing(6) spacing(9);

        &--download {
            padding-block: 5rem;
            padding-inline: 8.5rem;
        }
    }
}

.subscribe-icon {
    display: block;
    width: 100%;
    max-width: 90px;
    height: auto;

    @include media-breakpoint-up(md) {
        max-width: 115px;
    }

    @include media-breakpoint-up(lg) {
        .layout:not(.layout--small) & {
            max-width: 192px;
        }
    }
}

.subscribe-section {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__body {
        padding: spacing(5) 0 spacing(4);
    }

    @include media-breakpoint-up(md) {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: auto auto;
        grid-column-gap: spacing(6);
        align-items: flex-start;

        &__icon {
            svg {
                margin: 0 auto;
            }
        }

        &__body {
            padding: 0;

            & > :last-child {
                margin-bottom: 0;
            }
        }

        &__footer {
            grid-column-start: span 2;
        }

        & .invalid-feedback {
            position: absolute;
        }
    }

    @include media-breakpoint-up(lg) {
        .layout:not(.layout--small) & {
            align-items: center;

            &__icon {
                grid-row-start: span 2;
            }

            &__footer {
                grid-column-start: auto;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .layout:not(.layout--small) & {
            grid-template-columns: 2fr 3fr;
            grid-column-gap: spacing(8);
        }
    }

    @include media-breakpoint-up(xxl) {
        .layout:not(.layout--small) & {
            grid-column-gap: spacing(9);
        }
    }
}

.subscribe-section-feedback {
    grid-template-areas:
        ". content"
        ". content";

    & .subscribe-section__body {
        grid-area: content;
    }
}

.subscribe-text-field {
    @extend .text-field;

    overflow: visible;
    padding: spacing(2) spacing(2) spacing(2) 0;

    & .text-field__input {
        flex: 1 0;
        box-shadow: unset;
    }

    @include media-breakpoint-down(md) {
        flex-direction: column;
        border: none;
        background: none;
        box-shadow: none;
        gap: spacing(3);

        &:focus-within {
            box-shadow: none;
        }

        & .text-field__input {
            @include as-form-control();
        }

        &.is-invalid .text-field__input {
            border-color: $form-feedback-invalid-color;
        }
    }

    @include media-breakpoint-up(md) {
        margin-top: spacing(5);
    }

    @include media-breakpoint-up(lg) {
        .layout--small & {
            margin-top: spacing(6);
        }
    }
}

.subscribe__submit {
    @include media-breakpoint-up(md) {
        position: relative;
    }
}

.subscribe__disclaimer {
    margin: spacing(3) 0 0;
    font-size: $small-font-size;

    @include media-breakpoint-up(md) {
        margin-top: spacing(5);
        font-size: 1.125rem;
    }

    & a > span {
        background-color: $bg-light-alt;
    }
}
