.nav-mobile {
    position: fixed;
    z-index: 1050;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    transform: translateX(-100%);
    transition: transform var(--default-animation-speed) ease-in;

    &.is-active {
        display: flex;
    }

    &.is-open {
        transform: translateX(0);
        transition: transform var(--default-animation-speed) ease-out;
    }
}

.nav-mobile__panel {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    background-color: $white;

    &:not(.nav-mobile__panel--main) {
        display: none;
        transform: translateX(-100%);
        transition: transform var(--default-animation-speed) ease-in;

        &.is-active {
            display: flex;
        }

        &.is-open {
            transform: translateX(0);
            transition: transform var(--default-animation-speed) ease-out;
        }
    }
}

.nav-mobile__header {
    position: relative;
    display: flex;
    align-items: center;
    height: $header-height-xs;
    border-bottom: 1px solid $border-color;
    font-size: 1rem;
    font-weight: bold;

    @include media-breakpoint-up(sm) {
        height: $header-height-sm;
    }
}

.nav-mobile__close {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 0;
    margin-top: -20px;
}

.nav-mobile__scroll {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow-y: auto;
    padding-bottom: 24px;
    overscroll-behavior: contain;
}

.nav-mobile__list-item {
    @extend .h3;

    border-bottom: 1px solid $border-color;
}

.nav-mobile__top-item {
    @include button-reset;

    display: flex;
    width: 100%;
    padding: spacing(4) 0;
    margin: 0;
    color: $headings-color;
    font-weight: bold;

    &:hover {
        color: $primary;
    }

    .icon {
        margin-left: auto;
        color: $primary;
    }
}

.nav-mobile__header-back {
    @include button-reset;

    display: flex;
    align-items: center;
    padding: spacing(3) 0;
    color: $headings-color;

    .icon {
        margin-right: spacing(2);
        transform: rotate(180deg);
    }
}

.nav-mobile__group-link {
    display: flex;
    flex: 0 0 spacing(8);
    align-items: center;

    .btn {
        width: auto;
    }
}

.nav-mobile__sub-heading {
    @extend .h3;

    color: $headings-color;
}

a.nav-mobile__sub-heading:hover {
    color: $primary;
}

.nav-mobile__sub-item {
    @extend .intro-text;

    margin-bottom: spacing(2);
}

.nav-mobile__sub-list {
    margin-bottom: spacing(5);
}

.nav-mobile__sub-list-items {
    margin-top: spacing(3);
}

.nav-mobile__sub-list-item {
    margin-bottom: spacing(2);
}
