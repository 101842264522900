.header-search {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: spacing(2) spacing(3) spacing(2) spacing(2);
    border: none;
    background: $white;
    transform-origin: var(--header-search-transform-origin-x) center;
    transition: transform var(--default-animation-speed) ease-out;

    &:not([open]) {
        transform: scale(0);
    }

    &[open] {
        transform: scale(1);
    }

    &__form {
        display: flex;
        align-items: center;
        gap: spacing(2);
        height: 100%;
    }

    &__input {
        flex-grow: 1;
        width: 100%;
        height: 100%;
        padding-left: spacing(2);
        border: none;
    }

    .icon-button {
        width: auto;
        height: 100%;
        max-height: 56px;
        aspect-ratio: 1/1;
        border-radius: 50%;

        svg {
            width: 50%;
            height: 50%;
        }
    }

    &__submit {
        background-color: $primary;
        color: $white;
    }

    &__close {
        border: 2px solid $primary;
        background-color: $white;
        color: $primary;
    }

    @include media-breakpoint-up(sm) {
        padding: spacing(3) spacing(4) spacing(3) spacing(3);

        &__form {
            gap: spacing(3);
        }
    }
}
