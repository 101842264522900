/**
* @description UI - Table
* based on _table.scss from https://www.toyota-louwman.nl/
*/

@mixin th-type {
    background-color: #f0f3ff;
    color: $secondary;
    font-weight: bold;
    letter-spacing: 0.5px;

    @include media-breakpoint-up(md) {
        line-height: 1.55;
    }
}

.table {
    // explicit line-height with unit so different font-sizes line up vertically
    // this is necessary on media-breakpoint-only(xs)
    line-height: 1.5rem;
    letter-spacing: 0.5px;

    @include media-breakpoint-up(md) {
        line-height: 1.6875rem;
        letter-spacing: 0.56px;
    }

    table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
    }

    th {
        @include th-type;
    }

    td,
    th {
        padding: spacing(3);
        vertical-align: top;
        // word-break: break-word;

        &:not(:last-child) {
            padding-right: spacing(3);
        }
    }

    td {
        background-color: $white;
    }

    th:empty {
        display: none;
    }

    @include media-breakpoint-only(xs) {
        table {
            border-radius: $border-radius;
        }

        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
        }

        thead tr {
            // Screenreader friendly way of hiding text
            position: absolute;
            overflow: hidden;
            width: 1px;
            height: 1px;
            white-space: nowrap;
            clip: rect(0 0 0 0);
            clip-path: inset(50%);
        }

        tr:not(:last-of-type) {
            padding-bottom: spacing(4);
        }

        // Borders & radius
        tr {
            border-right: 1px solid $border-color;
            border-left: 1px solid $border-color;
        }

        tr:first-of-type {
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;

            td:first-of-type {
                border-top-left-radius: $border-radius;
                border-top-right-radius: $border-radius;
            }

            td:first-of-type::before {
                border-top-left-radius: $border-radius;
            }
        }

        tr:last-of-type {
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;

            td:last-of-type {
                border-bottom-right-radius: $border-radius;
                border-bottom-left-radius: $border-radius;
            }

            td:last-of-type::before {
                border-bottom-left-radius: $border-radius;
            }
        }

        td,
        th {
            // Reset
            padding: 0;

            &:not(:last-child) {
                padding: 0;
            }
        }

        td {
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid $border-color;

            &:first-of-type {
                border-top: 1px solid $border-color;
            }

            &::before,
            .table__pseudo-td {
                height: 100%;
                padding: spacing(3) 0;
            }

            &::before {
                @include th-type;

                content: attr(data-title);
                display: inline-flex;
                flex: 0 0 auto;
                align-items: flex-start;
                width: 45%;
                height: inherit;
                padding-right: spacing(3);
                padding-left: spacing(3);
            }

            .table__pseudo-td {
                width: 55%;
                padding-right: spacing(3);
                padding-left: spacing(3);
            }
        }
    }

    @include media-breakpoint-up(sm) {
        // Borders & radius
        th,
        tr:not(:last-of-type) td {
            border-bottom: 1px solid $border-color;
        }

        table {
            border: 1px solid $border-color;
            border-radius: $border-radius;
        }

        thead th {
            &:first-of-type {
                border-top-left-radius: $border-radius;
            }

            &:last-of-type {
                border-top-right-radius: $border-radius;
            }
        }

        tbody tr:last-of-type {
            td:first-of-type {
                border-bottom-left-radius: $border-radius;
            }

            td:last-of-type {
                border-bottom-right-radius: $border-radius;
            }
        }
    }
}

.table__caption {
    padding: 0 0 spacing(4) spacing(5);
    border-bottom: 1px solid $border-color;
    margin-top: spacing(4);
    background: url("#{$static-path}/svgs/icon-Info-secondary-color.svg") no-repeat;
    background-size: 24px;
    color: $text-color;
    font-size: $small-font-size;
    caption-side: bottom;

    @include media-breakpoint-up(md) {
        background-position: 0 2px;
    }
}
