.download-form {
    display: grid;
    gap: 1rem;

    @include media-breakpoint-up(xl) {
        grid-template-columns: 1fr 1fr;
        column-gap: spacing(8);
    }

    @include media-breakpoint-up(xxl) {
        column-gap: spacing(9);
    }

    // small reset
    :where(p, .form-field) {
        margin: 0;
    }

    h2 {
        margin-block-end: 0.5rem;

        @include media-breakpoint-up(md) {
            margin-block-end: 1rem;
        }

        @include media-breakpoint-up(xl) {
            max-inline-size: 15ch;
            margin-block-end: 1.5rem;
        }
    }

    p {
        @include media-breakpoint-up(xl) {
            max-inline-size: 30ch;
        }
    }

    :where(form) {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        gap: 0.5rem;
    }

    .form-actions {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-block-start: 0.5rem;
        gap: 1rem;

        @include media-breakpoint-up(xl) {
            margin-block-start: 1.5rem;
        }

        :where(.btn) {
            flex-shrink: 0;
        }

        :where(.rich-text) {
            flex: 1;
            margin: 0;
            font-size: 1rem;
        }

        :where(.rich-text a > span) {
            background-color: $bg-light-alt;
        }
    }
}
