//-- Layouts are the most top-level blocks.
.layout {
    //-- Spacing options
    &--spacing-top,
    &--spacing-both {
        padding-top: spacing(5);
    }

    &--spacing-bottom,
    &--spacing-both {
        padding-bottom: spacing(5);
    }

    @include media-breakpoint-up(lg) {
        &--spacing-top,
        &--spacing-both {
            padding-top: spacing(7);
        }

        &--spacing-bottom,
        &--spacing-both {
            padding-bottom: spacing(7);
        }
    }

    //-- Sizing options -- XL and up
    //
    // We need to resize the rows to the wanted column size, and make sure they're still
    // aligned in the middle so that content will stay in 12 columns. This is only
    // required for display sizes of breakpoint XL and up.
    @include media-breakpoint-up(lg) {
        .layout-container {
            padding-right: spacing(3);
            // To counter extra gutter width: https://getbootstrap.com/docs/5.2/layout/gutters/#horizontal-gutters
            padding-left: spacing(3);
        }

        // empty but available.
        &--large .layout-container {
            //
        }

        &--medium .layout-container {
            width: percentage(divide(10, $grid-columns));
            margin: 0 auto;
        }

        &--small .layout-container {
            width: percentage(divide(8, $grid-columns));
            margin: 0 auto;
        }
    }
}

.fullwidth-component-spacing--both {
    margin-top: spacing(5);
    margin-bottom: spacing(5);

    @include media-breakpoint-up(lg) {
        margin-top: spacing(7);
        margin-bottom: spacing(7);
    }

    // Hero (or a second fullwidth-compoennt) also has margin, so it collapses.
    // Padding is not an option because of background, so double the spacing
    .hero + &,
    & + & {
        margin-top: spacing(5) * 2;

        @include media-breakpoint-up(lg) {
            margin-top: spacing(7) * 2;
        }
    }
}

[class^="col"] {
    & > * {
        margin-bottom: spacing(3);

        @include media-breakpoint-up(lg) {
            margin-bottom: spacing(4);
        }
    }

    & > :last-child {
        margin-bottom: 0 !important;
    }
}

figure {
    margin: 0;
}

img {
    max-width: 100vw;
}
