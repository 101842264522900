.icon-button {
    @include button-reset;
    @include center-content;

    width: $icon-button-wh;
    height: $icon-button-wh;

    svg {
        display: inline-block;
        width: 25px;
        height: 25px;
    }
}

.icon-button--with-text {
    width: auto;
    min-width: $icon-button-wh;
    height: $icon-button-wh;
    border: 2px solid $headings-color;
    background-color: white;
    color: $headings-color;
    font-size: 16px;
    font-weight: $font-weight-bold;
    border-radius: 20px;

    @include media-breakpoint-up(sm) {
        min-width: 40px;
        height: 40px;
        padding: 0 spacing(4) 0 spacing(3);
    }

    .icon-button__text {
        display: none;

        @include media-breakpoint-up(sm) {
            display: block;
            margin-left: spacing(1);
        }
    }
}
