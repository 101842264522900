.title {
    display: inline-block;

    &__sub {
        @extend .h4;

        display: block;
        margin-bottom: spacing(3);
    }

    &__link {
        display: inline-block;
        margin-left: spacing(1);

        &:hover {
            color: $primary;
        }

        .icon-button {
            opacity: 0;

            .title:hover &,
            &:focus {
                opacity: 1;
            }

            &:focus {
                color: $primary;
            }
        }
    }
}
