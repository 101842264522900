.image-block {
    &__image {
        display: block;
        width: 100%;
        height: auto;
        border-radius: $border-radius;
    }

    &__caption {
        padding-bottom: spacing(4);
        padding-left: spacing(5);
        border-bottom: 1px solid $border-color;
        margin-top: spacing(4);
        background: url("#{$static-path}/svgs/icon-Info-secondary-color.svg") no-repeat;
        background-position: 0 4px;
        background-size: 24px;
    }
}
