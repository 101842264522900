.secondary-teaser {
    @extend .boxed-block;

    align-items: center;
    height: auto;

    @include media-breakpoint-up(md) {
        padding: spacing(5);
    }
    @include media-breakpoint-up(lg) {
        padding: 5rem;
    }

    &__body {
        display: flex;
        flex-direction: column;
        padding-bottom: spacing(5);

        @include media-breakpoint-up(lg) {
            flex: 1 1 66.6666%;
            padding-bottom: 0;
        }
    }

    &__actions {
        @extend .buttons-centered;

        flex: 1 1 auto;
        width: 100%;
        height: min-content;
        margin-bottom: 0;

        @include media-breakpoint-up(lg) {
            width: auto;

            & > .btn {
                flex: 1 1 100%;
            }
        }
    }

    & p {
        margin-bottom: 0;
        font-size: 1.25rem;
    }

    & h2:last-child {
        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }
    }
}

@include media-breakpoint-up(lg) {
    .secondary-teaser {
        flex-direction: row;
        gap: 3rem;
    }
}
