.vacancy-filter {
    display: flex;
    flex-direction: column;
    gap: spacing(2);
    margin-bottom: spacing(4);
    color: $secondary;

    label {
        font-weight: bold;
    }

    select {
        width: auto;
        padding-right: spacing(6);
        color: $secondary;
        font-weight: bold;
    }

    @include media-breakpoint-up(sm) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: spacing(5);
    }

    @include media-breakpoint-up(lg) {
        justify-content: flex-start;
        gap: spacing(4);
        margin-bottom: spacing(7);
    }
}
