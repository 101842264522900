.page-details {
    padding: spacing(4) 0;
    border-bottom: 1px solid $border-color;

    @include media-breakpoint-up(md) {
        padding: spacing(5) 0;
    }

    .container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: spacing(3);

        @include media-breakpoint-up(sm) {
            gap: spacing(5);
        }

        @include media-breakpoint-up(md) {
            gap: spacing(6);
        }

        // Only on article detail page
        & > * {
            display: flex;
            align-items: center;
        }

        svg {
            margin-right: spacing(2);

            @include media-breakpoint-up(md) {
                margin-right: spacing(3);
            }
        }
    }
}
