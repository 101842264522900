.teaser-card {
    // Note: despite having similarities with boxed-block, teaser-card
    // has too many exceptions, so porposely not extended.
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    background-color: $white;
    box-shadow:
        0 24px 80px rgb(0 0 0 / 4%),
        0 2px 8px rgb(0 0 0 / 7.72%);
}
// on overview pages the whole thing is a link
a.teaser-card:hover {
    color: $text-color;
    box-shadow: rgb(0 0 0 / 8%) 0 16px 24px 0;

    .teaser-card__button {
        color: $primary;

        .btn-tertiary__icon {
            @extend .circle-arrow-hover;
        }
    }
}

.teaser-card__image {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 104px;
    height: 104px;
    object-fit: cover;
    clip-path: ellipse(74% 112% at 73% -26%);

    @include media-breakpoint-up(md) {
        width: 144px;
        height: 144px;
    }

    @include media-breakpoint-up(lg) {
        position: relative;
        width: 100%;
        min-height: 200px;
        clip-path: ellipse(85% 60% at 50% 40%);
        border-top-right-radius: $border-radius;
        border-top-left-radius: $border-radius;
    }

    @include media-breakpoint-up(xl) {
        min-height: 280px;
    }
}

.teaser-card__body {
    z-index: 1;
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 160px; // enough room for image and link button
    padding: spacing(5) spacing(4) spacing(7);
    gap: spacing(3);

    @include media-breakpoint-up(md) {
        padding: 2.5rem 2.5rem spacing(7);
    }

    @include media-breakpoint-up(lg) {
        padding: spacing(5);
    }

    & > * {
        margin: 0;
    }

    & > h3 {
        margin-right: 4rem;
    }

    @include media-breakpoint-only(md) {
        & > h3 {
            margin-right: 5rem;
        }

        & > p {
            margin-right: 3rem;
        }
    }

    @include media-breakpoint-up(lg) {
        & > h3 {
            margin-right: 0;
        }
    }
}

.teaser-card__type {
    @extend .pagetype-pill;

    width: fit-content;

    @include media-breakpoint-up(lg) {
        position: absolute;
        z-index: 1;
        top: spacing(3);
        left: spacing(3);
    }
}

.teaser-card__button {
    justify-self: flex-end;
    width: fit-content;
    margin: auto 0 0;
    gap: spacing(2);

    @include media-breakpoint-down(lg) {
        position: absolute;
        right: 0;
        bottom: 0;
        margin: spacing(3);

        > .btn__title {
            position: absolute;
            overflow: hidden;
            width: 1px;
            height: 1px;
            padding: 0;
            border: 0;
            margin: -1px;
            white-space: nowrap;
            clip: rect(0, 0, 0, 0);
        }
    }
}

// On overview pages
.teaser-card__vacancy-props {
    display: none;

    @include media-breakpoint-up(sm) {
        display: flex;
        gap: spacing(3);
        padding: 0;
        list-style: none;
    }

    @include media-breakpoint-up(lg) {
        flex-direction: column;
        gap: spacing(2);
        margin-bottom: spacing(5);
    }

    & > li {
        display: flex;
        align-items: center;

        @include media-breakpoint-up(lg) {
            gap: spacing(2);
        }
    }

    & .teaser-card__vacancy-props__icon {
        margin-right: spacing(2);
        text-align: center;

        svg {
            max-width: 21px;

            &.icon-team {
                max-width: spacing(5);
            }
        }

        @include media-breakpoint-up(lg) {
            width: spacing(5);
            margin-right: 0;
        }
    }
}
