.content-teaser {
    overflow: hidden;

    & .squiffy-bg-component__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
            margin-bottom: 0;
        }
    }

    &__navigation {
        display: flex;
        gap: spacing(3);

        @media (pointer: coarse) {
            display: none;
        }
    }

    & .squiffy-bg-component__body {
        position: relative;
        padding: 0;
        background: transparent;
        box-shadow: none;
    }

    &__slider {
        gap: spacing(3);
        padding: 0 calc(50vw - 50%);
        scroll-padding-left: spacing(4);

        @include media-breakpoint-up(md) {
            gap: spacing(5);
            scroll-padding-left: spacing(5);
        }

        @include media-breakpoint-up(lg) {
            gap: spacing(6);
            // space on the left with squiffy container padding added
            scroll-padding-left: calc(
                ((100% - #{map-get($container-max-widths, lg)}) / 2) + #{spacing(5)}
            );
        }

        @include media-breakpoint-up(xl) {
            gap: spacing(7);
            scroll-padding-left: calc(
                ((100% - #{map-get($container-max-widths, xl)}) / 2) + #{spacing(5)}
            );
        }

        @include media-breakpoint-up(xxl) {
            scroll-padding-left: calc(
                ((100% - #{map-get($container-max-widths, xxl)}) / 2) + #{spacing(5)}
            );
        }
    }

    &__slide {
        display: flex;
        flex-direction: column;
        //                     container padding + slider gap
        min-width: calc(100vw - #{spacing(4) * 2 + spacing(3)});
        border-radius: $border-radius;

        @include media-breakpoint-up(md) {
            min-width: 630px;
        }

        @include media-breakpoint-up(lg) {
            min-width: 50vw;
        }
    }

    &__image {
        display: block;
        width: 100%;
        height: 360px;
        object-fit: cover;
        border-radius: $border-radius $border-radius 0 0;
        clip-path: none;

        @include media-breakpoint-up(md) {
            border-radius: $border-radius;
        }

        @include media-breakpoint-up(lg) {
            height: 30vw;
        }
    }

    &__card {
        position: relative;
        flex: 1 0 auto;
        min-height: 280px;
        padding: spacing(4) spacing(4) spacing(6);
        border: 1px solid $border-color;
        border-top: none;
        background-color: $white;
        border-radius: 0 0 $border-radius $border-radius;
        box-shadow:
            0 12px 20px rgb(0 0 0 / 2%),
            0 2px 8px rgb(0 0 0 / 7.72%);

        &:hover {
            color: $text-color;

            & .content-teaser__button {
                @extend .circle-arrow-hover;
            }
        }

        @include media-breakpoint-up(md) {
            $pull-up: -#{spacing(9)};

            top: $pull-up;
            max-width: 570px;
            padding: spacing(5);
            border-top: 1px solid $border-color;
            margin-bottom: $pull-up;
            border-top-right-radius: $border-radius;
        }

        @include media-breakpoint-up(lg) {
            $pull-up: -#{spacing(9)};

            top: $pull-up;
            max-width: 40vw;
            margin-bottom: $pull-up;
        }

        & h3 {
            color: $secondary;
            font-weight: 700;
            line-height: 36px;
            letter-spacing: 0.5833;
        }

        & p {
            margin-bottom: spacing(5);
            font-size: 20px;
            font-weight: 300;
            line-height: 30px;
            letter-spacing: 0.63px;
            opacity: 1;
        }
    }

    &__card-subtitle {
        margin: 0;
        color: $primary;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.6px;
    }

    &__button {
        @extend .circle-arrow;

        position: absolute;
        right: 0;
        bottom: 0;
        width: 40px;
        height: 40px;
        margin: spacing(3);
        border-radius: 50%;
        color: $primary;

        @include media-breakpoint-up(lg) {
            width: 56px;
            height: 56px;
        }
    }
}
