.faq {
    border-bottom: 1px solid $border-color;

    --font-size-question: 18px;
    --font-size-answer: 18px;

    @include media-breakpoint-up(md) {
        --font-size-question: 24px;
        --font-size-answer: 20px;
    }

    &:first-of-type {
        border-top: 1px solid $border-color;
    }

    &[open] {
        & .faq-question {
            color: $primary;

            &::after {
                transform: rotate(180deg);
            }
        }
    }
}

.faq-category {
    & ~ & {
        margin-block-start: spacing(7);

        @include media-breakpoint-up(lg) {
            margin-block-start: spacing(9);
        }
    }
}

.faq-question {
    position: relative;
    padding: 1rem 0;
    padding-right: 2.5rem;
    color: $secondary;
    font-size: var(--font-size-question);
    font-weight: bold;

    @extend %red-chevron-background;
}

.faq-answer {
    font-size: var(--font-size-answer);
}

%red-chevron-background {
    list-style: none;

    &::-webkit-details-marker {
        display: none;
    }

    &::after,
    &::before {
        content: "";
        position: absolute;
        top: 1rem;
        right: 0;
        display: block;
        width: 2rem;
        height: 2rem;
        border-radius: 1rem;
    }

    &::after {
        background: url("/static/spring/svgs/icon-chevron-wide-down-red.svg") no-repeat center;
        transition: transform 0.1s ease;
        transform: rotate(0deg);
        transform-origin: center;
    }

    &:hover {
        &::after {
            background: url("/static/spring/svgs/icon-chevron-wide-down-white.svg") no-repeat center;
        }

        &::before {
            border: none;
            background: linear-gradient(90deg, $active-gradient-start, $active-gradient-end);
        }
    }

    &::before {
        border: 2px solid $primary;
    }
}
