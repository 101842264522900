.tab-list {
    &__tabs {
        display: flex;
        flex-direction: column;
        padding: spacing(4) 0 spacing(3);

        @include media-breakpoint-up(md) {
            flex-direction: row;
            margin-bottom: 0;
        }

        @include media-breakpoint-up(lg) {
            padding: spacing(6) 0 0;
        }

        a {
            display: block;
            flex: 1;
            padding-left: spacing(3);
            border-left: 2px solid $border-color;
            color: $secondary;
            font-size: 18px;
            font-weight: 700;
            line-height: 32px;

            @include media-breakpoint-up(md) {
                padding-bottom: spacing(3);
                padding-left: 0;
                border-bottom: 2px solid $border-color;
                border-left: none;
                text-align: center;
            }

            @include media-breakpoint-up(xxl) {
                padding-bottom: spacing(5);
                font-size: 24px;
            }

            &.active {
                position: relative;
                color: $primary;
                border-color: $primary;

                @include media-breakpoint-up(md) {
                    &::after {
                        content: "";
                        position: absolute;
                        right: 0;
                        bottom: -3px;
                        left: 0;
                        height: 2px;
                        background: $primary;
                        border-radius: 2px;

                        @include media-breakpoint-up(lg) {
                            height: 4px;
                        }
                    }
                }
            }

            &:only-child {
                pointer-events: none;
            }
        }
    }

    &__panel {
        display: none;
        padding: spacing(4);
        border-top: 1px solid $border-color;
        margin: 0 -#{spacing(4)};

        &--center {
            > .row {
                align-items: center;
            }
        }

        &.active {
            display: block;
        }

        .col-12 + .col-12 {
            margin-top: spacing(4);
        }

        @include media-breakpoint-up(md) {
            border-top: none;

            .col-12 + .col-12 {
                margin-top: spacing(6);
            }
        }

        @include media-breakpoint-up(lg) {
            padding-top: spacing(7);
            padding-bottom: spacing(7);

            > .row {
                --bs-gutter-x: #{spacing(5)};
            }

            .col-12 + .col-12 {
                margin-top: 0;
            }
        }
    }
}
