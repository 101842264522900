@mixin focus-outline {
    outline: $focus-color solid 2px;
    outline-offset: 1px;
}

@mixin button-reset {
    display: inline-block;
    padding: 0;
    border: none;
    background-color: transparent;
    color: inherit;
    font: inherit;
    text-align: inherit;
    text-decoration: none;
}

@mixin center-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin fouc-display-block {
    display: block;
    opacity: 1;
}

@mixin block-bottom-margin {
    margin-bottom: spacing(3);
    @include media-breakpoint-up(lg) {
        margin-bottom: spacing(4);
    }
}
