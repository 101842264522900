.nav-desktop {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
    list-style: none;

    &__item:hover &__link {
        color: $primary;
    }

    &__link {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        height: $header-height-xs;
        padding: spacing(2) 0;
        border-bottom: 2px solid transparent;
        margin: 0 0.75rem;
        color: $headings-color;
        font-size: 1.125rem;
        font-weight: $font-weight-bold;
        cursor: pointer;

        .nav-desktop__item.is-active & {
            color: $primary;
            border-bottom-color: $primary;
        }

        @include media-breakpoint-up(sm) {
            height: $header-height-sm;
        }

        @include media-breakpoint-up(lg) {
            height: $header-height-lg;
        }

        @include media-breakpoint-up(xl) {
            margin: 0 spacing(4);
        }
    }

    &__link-caret {
        width: 20px;
        height: 20px;
        margin-left: 4px;
        color: $primary;
        transform: rotate(0);
        transition: transform 100ms ease-in-out;

        .nav-desktop__item.is-active & {
            transform: rotate(180deg);
            transition-duration: 150ms;
        }
    }

    &__menu-panel {
        $_rounded-corner-mask: 6px;

        @extend .container;

        display: none;

        @include media-breakpoint-up(lg) {
            position: absolute;
            top: calc($header-height-lg - $_rounded-corner-mask);
            left: 0;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            padding-bottom: 32px; // just enough for child box-shadow
            visibility: hidden;
            transform: scale(0);
            transition: transform 0ms;
            transition-delay: calc(
                $header-desktop-menu-delay-out + $header-desktop-menu-anim-duration
            );

            .nav-desktop__item.is-active & {
                visibility: visible;
                transform: scale(1);
                transition: transform 0ms 50ms;
            }

            .is-scrolling-up &,
            .is-scrolled & {
                max-width: none;
                padding-right: 0;
                padding-left: 0;
            }

            &__spacer {
                height: $_rounded-corner-mask;
                background-color: $white;
            }
        }
    }
}
