/* stylelint-disable indentation */

.multi-select {
    --ss-primary-color: #{$primary};
    --ss-bg-color: #fff;
    --ss-font-color: #000;
    --ss-font-placeholder-color: #8d8d8d;
    --ss-disabled-color: #dcdee2;
    --ss-border-color: #{$secondary};
    --ss-highlight-color: #fffb8c;
    --ss-success-color: #00b755;
    --ss-error-color: #dc3545;
    --ss-focus-color: #{$primary};
    --ss-main-height: 3rem;
    --ss-content-height: 20rem;
    --ss-spacing-inline-l: 0.8rem;
    --ss-spacing-block-l: 0.8rem;
    --ss-spacing-inline-m: 0.6rem;
    --ss-spacing-block-m: 0.6rem;
    --ss-spacing-inline-s: 0.4rem;
    --ss-spacing-block-s: 0.4rem;
    --ss-animation-timing: 0.2s;
    --ss-border-radius: 3rem;
    --ss-font-size: 1rem;

    display: flex;
    min-width: 12rem;
    max-width: 25rem;

    @include media-breakpoint-up(md) {
        min-width: 20rem;
    }
}

.multi-selected-items {
    display: flex;
    border-top: 1px solid $gray-300;
}

.multi-select-component {
    display: none;
}

.ss-content.multi-select-component {
    --ss-primary-color: #{$primary};
    --ss-bg-color: #fff;
    --ss-font-color: #000;
    --ss-font-placeholder-color: #8d8d8d;
    --ss-disabled-color: #dcdee2;
    --ss-border-color: #{$secondary};
    --ss-highlight-color: #fffb8c;
    --ss-success-color: #00b755;
    --ss-error-color: #dc3545;
    --ss-focus-color: #{$primary};
    --ss-content-height: 20rem;
    --ss-spacing-inline-l: 0.8rem;
    --ss-spacing-block-l: 0.8rem;
    --ss-spacing-inline-m: 0.6rem;
    --ss-spacing-block-m: 0.6rem;
    --ss-spacing-inline-s: 0.4rem;
    --ss-spacing-block-s: 0.4rem;
    --ss-animation-timing: 0.2s;
    --ss-border-radius: 3rem;
    --ss-font-size: 1rem;
}

/* stylelint-disable-next-line keyframes-name-pattern */
@keyframes ss-valueIn {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

/* stylelint-disable-next-line keyframes-name-pattern */
@keyframes ss-valueOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(0);
        opacity: 0;
    }
}

.ss-hide {
    display: none !important;
}

.ss-main {
    position: relative;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    min-height: var(--ss-main-height);
    padding: var(--ss-spacing-inline-s) var(--ss-spacing-block-s);
    border: var(--ss-border-width, 1px) solid var(--ss-border-color);
    background-color: var(--ss-bg-color);
    color: var(--ss-font-color);
    user-select: none;
    cursor: pointer;
    border-radius: var(--ss-border-radius);
    outline: 0;
    transition: background-color var(--ss-animation-timing);
    font-size: var(--ss-font-size);
    box-shadow: var(--ss-box-shadow, none);
}

.ss-main.ss-disabled {
    background-color: var(--ss-disabled-color);
    cursor: not-allowed;
}

.ss-main.ss-disabled .ss-values .ss-disabled {
    color: var(--ss-font-color);
}

.ss-main.ss-disabled .ss-values .ss-value .ss-value-delete {
    cursor: not-allowed;
}

.ss-main.ss-open-above {
    border-radius: 0 0 calc(var(--ss-border-radius) / 2) calc(var(--ss-border-radius) / 2);
}

.ss-main.ss-open-below {
    border-radius: calc(var(--ss-border-radius) / 2) calc(var(--ss-border-radius) / 2) 0 0;
}

.ss-main .ss-values {
    display: inline-flex;
    flex: 1 1 100%;
    flex-wrap: wrap;
    gap: var(--ss-spacing-m);
}

.ss-main .ss-values .ss-placeholder {
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 100%;
    padding: var(--ss-spacing-inline-s) var(--ss-spacing-block-m) var(--ss-spacing-inline-s)
        var(--ss-spacing-block-m);
    margin: auto 0;
    color: var(--ss-font-placeholder-color);
    line-height: 1em;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ss-main .ss-values .ss-max {
    display: flex;
    align-items: center;
    width: fit-content;
    padding: var(--ss-spacing-inline-s) var(--ss-spacing-block-m);
    background-color: var(--ss-primary-color);
    color: var(--ss-bg-color);
    font-size: 12px;
    line-height: 1;
    user-select: none;
    border-radius: var(--ss-border-radius);
}

.ss-main .ss-values .ss-single {
    display: flex;
    margin: auto 0 auto var(--ss-spacing-block-s);
}

.ss-main .ss-values .ss-value {
    display: flex;
    user-select: none;
    align-items: center;
    width: fit-content;
    background-color: var(--ss-primary-color);
    border-radius: var(--ss-border-radius);
    animation-name: ss-valueIn;
    animation-duration: var(--ss-animation-timing);
    animation-timing-function: ease-out;
    animation-fill-mode: both;
}

.ss-main .ss-values .ss-value.ss-value-out {
    animation-name: ss-valueOut;
    animation-duration: var(--ss-animation-timing);
    animation-timing-function: ease-out;
}

.ss-main .ss-values .ss-value .ss-value-text {
    padding: var(--ss-spacing-inline-s) var(--ss-spacing-block-m);
    color: var(--ss-bg-color);
    font-size: 12px;
    line-height: 1;
}

.ss-main .ss-values .ss-value .ss-value-delete {
    display: flex;
    align-items: center;
    box-sizing: content-box;
    width: 0.5rem;
    height: 0.5rem;
    padding: var(--ss-spacing-inline-s) var(--ss-spacing-block-m);
    border-left: solid 1px var(--ss-bg-color);
    cursor: pointer;
}

.ss-main .ss-values .ss-value .ss-value-delete svg {
    inline-size: var(--ss-spacing-inline-l);
}

.ss-main .ss-values .ss-value .ss-value-delete svg path {
    fill: none;
    stroke: var(--ss-bg-color);
    stroke-width: 18;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.ss-main .ss-deselect {
    display: flex;
    flex: 0 1 auto;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: auto;
    margin: 0 var(--ss-spacing-block-m) 0 var(--ss-spacing-block-m);
}

.ss-main .ss-deselect svg {
    width: 8px;
    height: 8px;
}

.ss-main .ss-deselect svg path {
    fill: none;
    stroke: var(--ss-font-color);
    stroke-width: 20;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.ss-main .ss-arrow {
    display: flex;
    flex: 0 1 auto;
    justify-content: flex-end;
    align-items: center;
    width: 12px;
    height: 12px;
    margin: auto var(--ss-spacing-block-m) auto var(--ss-spacing-block-m);
}

.ss-main .ss-arrow path {
    fill: none;
    stroke: var(--ss-font-color);
    stroke-width: 18;
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: var(--ss-animation-timing);
    transition-timing-function: ease-out;
}

.ss-content {
    position: absolute;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-sizing: border-box;
    width: auto;
    height: auto;
    max-height: var(--ss-content-height);
    border: 1px solid var(--ss-border-color);
    background-color: var(--ss-bg-color);
    transition: transform var(--ss-animation-timing), opacity var(--ss-animation-timing);
    opacity: 0;
    transform: scaleY(0);
    transform-origin: center top;
}

.ss-content.ss-relative {
    position: relative;
    height: 100%;
}

.ss-content.ss-open-above {
    flex-direction: column-reverse;
    opacity: 1;
    transform: scaleY(1);
    transform-origin: center bottom;
    border-radius: calc(var(--ss-border-radius) / 2) calc(var(--ss-border-radius) / 2) 0 0;
}

.ss-content.ss-open-below {
    opacity: 1;
    transform: scaleY(1);
    transform-origin: center top;
    border-radius: 0 0 calc(var(--ss-border-radius) / 2) calc(var(--ss-border-radius) / 2);
}

.ss-content .ss-search {
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    padding: var(--ss-spacing-inline-l) var(--ss-spacing-block-l) var(--ss-spacing-inline-m)
        var(--ss-spacing-block-l);
}

.ss-content .ss-search input {
    display: inline-flex;
    flex: 1 1 auto;
    box-sizing: border-box;
    width: 100%;
    min-width: 0;
    padding: var(--ss-spacing-inline-m) var(--ss-spacing-block-l);
    border: 1px solid var(--ss-border-color);
    margin: 0;
    background-color: var(--ss-bg-color);
    font-size: inherit;
    line-height: inherit;
    text-align: left;
    border-radius: var(--ss-border-radius);
    outline: 0;
}

.ss-content .ss-search input::placeholder {
    color: var(--ss-font-placeholder-color);
    vertical-align: middle;
}

.ss-content .ss-search .ss-addable {
    display: inline-flex;
    flex: 0 0 auto;
    justify-content: center;
    align-items: center;
    height: auto;
    border: var(--ss-border-width, 1px) solid var(--ss-border-color);
    margin: 0 0 0 var(--ss-spacing-m);
    cursor: pointer;
    border-radius: var(--ss-border-radius);
}

.ss-content .ss-search .ss-addable svg {
    display: flex;
    flex: 0 1 auto;
    justify-content: flex-end;
    align-items: center;
    width: 12px;
    height: 12px;
    margin: auto var(--ss-spacing-m) auto var(--ss-spacing-m);
}

.ss-content .ss-search .ss-addable svg path {
    fill: none;
    stroke: var(--ss-font-color);
    stroke-width: 18;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.ss-content .ss-list {
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
    height: auto;
    padding-bottom: 0.5rem;
}

.ss-content .ss-list .ss-error {
    padding: var(--ss-spacing-l);
    color: var(--ss-error-color);
}

.ss-content .ss-list .ss-searching {
    padding: var(--ss-spacing-l);
    color: var(--ss-font-color);
}

.ss-content .ss-list .ss-optgroup.ss-close .ss-option {
    display: none !important;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: var(--ss-spacing-inline-m) var(--ss-spacing-block-l) var(--ss-spacing-inline-m)
        var(--ss-spacing-block-l);
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-label-text {
    flex: 1 1 auto;
    color: var(--ss-font-color);
    font-weight: bold;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label:has(.ss-arrow) {
    cursor: pointer;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions {
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: var(--ss-spacing-inline-m);
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions .ss-selectall {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    cursor: pointer;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions .ss-selectall:hover {
    opacity: 0.5;
}

.ss-content
    .ss-list
    .ss-optgroup
    .ss-optgroup-label
    .ss-optgroup-actions
    .ss-selectall.ss-selected
    svg
    path {
    stroke: var(--ss-error-color);
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions .ss-selectall span {
    display: flex;
    flex: 0 1 auto;
    justify-content: center;
    align-items: center;
    padding: 0 var(--ss-spacing-block-s) 0 0;
    font-size: var(--ss-font-size);
    text-align: center;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions .ss-selectall svg {
    flex: 0 1 auto;
    width: 13px;
    height: 13px;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions .ss-selectall svg path {
    fill: none;
    stroke: var(--ss-success-color);
    stroke-linecap: round;
    stroke-linejoin: round;
}

.ss-content
    .ss-list
    .ss-optgroup
    .ss-optgroup-label
    .ss-optgroup-actions
    .ss-selectall
    svg:first-child {
    stroke-width: 5;
}

.ss-content
    .ss-list
    .ss-optgroup
    .ss-optgroup-label
    .ss-optgroup-actions
    .ss-selectall
    svg:last-child {
    stroke-width: 11;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions .ss-closable {
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    cursor: pointer;
}

.ss-content .ss-list .ss-optgroup .ss-optgroup-label .ss-optgroup-actions .ss-closable .ss-arrow {
    flex: 1 1 auto;
    width: 10px;
    height: 10px;
}

.ss-content
    .ss-list
    .ss-optgroup
    .ss-optgroup-label
    .ss-optgroup-actions
    .ss-closable
    .ss-arrow
    path {
    fill: none;
    stroke: var(--ss-font-color);
    stroke-width: 18;
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: var(--ss-animation-timing);
    transition-timing-function: ease-out;
}

.ss-content .ss-list .ss-optgroup .ss-option {
    padding: 0.5rem;
}

.ss-content .ss-list .ss-option {
    display: flex;
    padding: 0.5rem 0.8rem;
    color: var(--ss-font-color);
    cursor: pointer;
    user-select: none;
    transition: all 350ms ease-out;
    font-size: var(--ss-font-size, 1rem);
}

.ss-content .ss-list .ss-option:hover {
    padding-left: 1rem;
    background-color: rgba($primary, 0.1);
    color: black;
}

.ss-content .ss-list .ss-option.ss-highlighted,
.ss-content .ss-list .ss-option:not(.ss-disabled).ss-selected {
    background-color: rgba($primary, 0.1);
    color: $primary;
    color: black;
}

.ss-content .ss-list .ss-option.ss-disabled {
    cursor: not-allowed;
    background-color: var(--ss-disabled-color);
}

.ss-content .ss-list .ss-option.ss-disabled:hover {
    color: var(--ss-font-color);
}

.ss-content .ss-list .ss-option .ss-search-highlight {
    background-color: var(--ss-highlight-color);
}
