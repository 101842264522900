.primary-teaser {
    display: flex;
    flex-direction: column;
    background-color: $bg-light;

    @include media-breakpoint-up(lg) {
        flex-direction: row;
        gap: spacing(6);
    }

    @include media-breakpoint-up(xl) {
        gap: spacing(7);
    }
}

.primary-teaser__body-col {
    order: 2;
    padding-right: 0;
    padding-left: 0;
    @extend .container;

    @include media-breakpoint-up(lg) {
        display: flex;
        flex: 0 0 45vw;
        order: 1;
        align-items: center;
        padding-right: var(--bs-gutter-x, 0.75rem);
        padding-left: var(--bs-gutter-x, 0.75rem);
    }
}

.primary-teaser__picture-col {
    order: 1;

    @include media-breakpoint-up(lg) {
        flex: 1 1 auto;
        order: 2;
    }
}

.primary-teaser__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: spacing(5) $grid-gutter-half-width;

    @include media-breakpoint-only(lg) {
        margin-left: calc((100vw - #{map-get($container-max-widths, lg)}) / 2);
    }
    @include media-breakpoint-only(xl) {
        margin-left: calc((100vw - #{map-get($container-max-widths, xl)}) / 2);
    }
    @include media-breakpoint-only(xxl) {
        margin-left: calc((100vw - #{map-get($container-max-widths, xxl)}) / 2);
    }

    h3 {
        margin-bottom: spacing(3);

        @include media-breakpoint-up(md) {
            margin-bottom: spacing(5);
        }
    }
}

.primary-teaser__image {
    display: block;
    width: 100%;
    max-height: 280px;
    object-fit: cover;
    clip-path: ellipse(85% 60% at 50% 40%);

    @include media-breakpoint-up(sm) {
        max-height: 360px;
    }

    @include media-breakpoint-up(md) {
        max-height: 480px;
    }

    @include media-breakpoint-up(lg) {
        left: unset;
        min-height: 800px;
        margin-left: unset;
        clip-path: ellipse(calc(100% - 2px) 120% at 100% 50%);
    }
}

.primary-teaser__actions {
    display: flex;
    gap: spacing(3);
    margin-top: spacing(3);
}

.primary-teaser__btn-cta {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: spacing(3);
    border: none;
    background-color: rgb(255 255 255 / 100%);
    color: $secondary;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    gap: spacing(3);
    border-radius: 8px;
    opacity: 1;
    box-shadow: 0 16px 40px rgb(0 0 0 / 4%), 0 2px 8px rgb(0 0 0 / 7.72%);
    transition: all ease 0.3s;

    @include media-breakpoint-up(md) {
        padding: spacing(4);
    }

    svg {
        width: 48px;
        height: 48px;
        color: $primary;
        transition: all ease 0.3s;

        @include media-breakpoint-up(md) {
            width: 64px;
            height: 64px;
        }
    }

    &:hover {
        background-color: $primary;
        color: $white;
        box-shadow: none;

        & > * {
            color: $white;
        }

        svg {
            color: $white;
        }
    }

    .h4 {
        margin-bottom: 0;
    }
}
