.overview-grid {
    display: grid;
    gap: spacing(3);
    margin-bottom: spacing(5);

    @include media-breakpoint-up(md) {
        gap: spacing(4);
    }

    @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(3, 1fr);
        gap: spacing(5);
        margin-bottom: spacing(8);
    }

    @include media-breakpoint-up(xxl) {
        gap: spacing(6);
    }
}

.overview-notfound-title {
    margin-bottom: spacing(7);
}

.filter-types {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .btn.btn-tertiary {
        width: fit-content;
    }
}

.filters-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin-bottom: 1.5rem;
    gap: 1rem;

    .multi-select {
        margin-left: auto;
    }

    .multi-selected-items {
        grid-column: span 2;
    }
}
