$hero-lg-height: 100vh;
$hero-lg-min-height: 800px;

// default styles all sizes
.hero {
    margin-bottom: spacing(5);
    background-color: $bg-light;

    @include media-breakpoint-up(lg) {
        margin-bottom: spacing(7);
    }

    .detail-page & {
        margin-bottom: 0;
    }
}

.hero--large,
.hero--medium {
    // clip the picture
    overflow: hidden;
}

.hero__container {
    @extend .container;

    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0;

    @include media-breakpoint-down(lg) {
        :not(.hero--small) & {
            max-width: none;
        }
    }

    @include media-breakpoint-up(lg) {
        flex-direction: row;
        height: $hero-lg-height;
        min-height: $hero-lg-min-height;

        .hero--medium & {
            height: auto;
            min-height: 80vh;
        }
    }

    .hero--small & {
        padding-top: 144px;

        @include media-breakpoint-up(sm) {
            padding-top: calc(#{$header-top-sm} + #{$header-height-sm} + max(80px, 10vh));
        }

        @include media-breakpoint-up(md) {
            padding-top: calc(#{$header-top-sm} + #{$header-height-sm} + max(80px, 14vh));
        }

        @include media-breakpoint-up(lg) {
            height: auto;
            min-height: 480px;
            padding-top: calc(#{$header-top-lg} + #{$header-height-lg} + #{spacing(9)});
        }
    }
}

.hero__header__col {
    order: 2;
    width: 100%;
    padding: spacing(3) $grid-gutter-half-width spacing(3);
    margin: 0 auto;

    h1 {
        font-size: 1.5rem;
    }

    @include media-breakpoint-only(sm) {
        max-width: map-get($container-max-widths, sm);
    }

    @include media-breakpoint-only(md) {
        max-width: map-get($container-max-widths, md);
        padding-top: spacing(5);
        padding-bottom: spacing(6);
    }

    @include media-breakpoint-up(lg) {
        display: flex;
        flex: 0 0 45%;
        flex-direction: column;
        order: unset;
        justify-content: center;
        width: unset;
        margin: calc(#{$header-top-lg} + #{$header-height-lg}) 0 0;

        .hero--large & {
            padding-left: spacing(5);
        }

        @media (max-height: 900px) {
            margin-top: calc(#{$header-top-lg} + #{$header-height-lg});
        }

        @media (max-height: 600px) {
            h1 {
                font-size: 2.5rem;
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        flex: 0 0 40%;
        padding-right: spacing(8);
    }

    .hero--small & {
        padding-top: 0;
        margin-top: 0;

        @include media-breakpoint-up(md) {
            width: 100%;
        }

        @include media-breakpoint-up(lg) {
            flex: unset;
            padding-bottom: spacing(8);
        }
    }
}

.blog,
.news {
    .hero__header__col {
        h1 {
            font-size: clamp(3.5rem, 3.75vw, 4.5rem);
        }
    }
}

.hero__picture__col {
    position: relative;
    order: 1;
    padding: 0;

    @include media-breakpoint-up(lg) {
        order: unset;
    }
}

.hero__picture {
    user-select: none;

    @include media-breakpoint-up(lg) {
        position: absolute;
        width: 70vw; // If this changes, adjust `sizes` in source block html
        height: $hero-lg-height;
        min-height: $hero-lg-min-height;

        .hero--medium & {
            height: 100%;
            min-height: 80vh;
        }
    }

    &__image {
        display: block;
        width: 100%;
        max-height: 60vh;
        object-fit: cover;
        clip-path: ellipse(85% 60% at 50% 40%);

        @include media-breakpoint-up(lg) {
            height: 100%;
            max-height: unset;
            object-position: left;
            clip-path: ellipse(calc(100% - 1rem) 120% at 100% 50%);
        }
    }
}
