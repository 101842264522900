.usp-list {
    display: flex;
    flex-direction: column;
    gap: spacing(3);
    justify-content: center;
    padding: 0 spacing(2);

    @include media-breakpoint-up(md) {
        gap: spacing(4);
    }

    @include media-breakpoint-up(lg) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: spacing(7);
    }
}

.usp-block {
    display: flex;
    flex: 1 1 0;
    gap: spacing(4);

    @include media-breakpoint-up(md) {
        gap: spacing(6);
    }

    @include media-breakpoint-up(lg) {
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: unset;
    }
}

.usp-block__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    margin-bottom: spacing(4);
    color: $primary;

    @include media-breakpoint-up(md) {
        width: 72px;
        height: 72px;
    }
}

.usp-block__text p {
    margin-bottom: 0;
}
