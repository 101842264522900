.checkbox-list {
    list-style: none;
    padding: 0;

    li {
        padding: 0 0 spacing(2) spacing(5);
        background: url("/static/spring/svgs/icon-check.svg") no-repeat 0 6px;
        background-size: 20px;

        &:last-child {
            padding-bottom: 0;
        }
    }

    p {
        margin: 0;
    }
}
