.search-form {
    min-height: spacing(7);
    margin-bottom: spacing(7);
    background: linear-gradient(180deg, $bg-light 0%, $bg-light 66.66%, $white 66.66%);

    @include media-breakpoint-up(md) {
        margin-bottom: spacing(8);
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: spacing(9);
    }

    &__form {
        position: relative;
        display: flex;
        flex-direction: column-reverse;
    }

    &__form-field {
        display: flex;
        align-items: center;
        gap: spacing(3);
        height: 64px;
        padding: spacing(2) spacing(3) spacing(2) spacing(2);
        background: $white;
        border-radius: $border-radius;
        box-shadow: rgb(0 0 0 / 8%) 0 16px 24px 0;

        @include media-breakpoint-up(md) {
            height: 88px;
            padding: spacing(3) spacing(4) spacing(3) spacing(3);
        }

        @include media-breakpoint-up(lg) {
            height: 104px;
            box-shadow: rgb(0 0 0 / 8%) 0 24px 64px 0;
        }
    }

    &__input {
        flex: 1 0;
        height: 100%;
        padding-left: spacing(2);
        border: none;

        @include media-breakpoint-up(md) {
            padding-left: spacing(3);
        }
    }

    &__label {
        @extend .h4;
    }

    @mixin icon-button {
        width: auto;
        height: 100%;
        max-height: 56px;
        aspect-ratio: 1/1;
        border-radius: 50%;
    }

    &__erase {
        @include icon-button;

        border-radius: unset;

        svg {
            width: auto;
            height: 32px;

            @include media-breakpoint-up(md) {
                width: auto;
                height: 40px;
            }
        }
    }

    &__submit {
        @include icon-button;

        display: none;

        @include media-breakpoint-up(lg) {
            display: flex;
            background-color: $primary;
            color: $white;

            svg {
                width: 50%;
                height: 50%;
            }
        }
    }
}
