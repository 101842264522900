.wizard {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $bg-light;
}

.wizard-header {
    position: sticky;
    z-index: 1;
    top: 0;
    background: $bg-light;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: spacing(3);
        padding-bottom: spacing(3);

        @include media-breakpoint-up(sm) {
            padding-top: spacing(5);
            padding-bottom: spacing(5);
        }

        @include media-breakpoint-up(lg) {
            padding-top: spacing(6);
            padding-bottom: spacing(6);
        }

        @media screen and (max-height: 500px) {
            padding-top: spacing(3);
            padding-bottom: spacing(3);
        }
    }

    & .icon-button {
        background-color: $bg-light;

        &:hover {
            background-color: $secondary;
            color: $white;
            box-shadow: rgb(0 0 0 / 8%) 0 16px 24px 0;
        }
    }
}

.wizard-body {
    position: relative;
    flex: 1 0 0;
    overflow: hidden;
}

.wizard-step {
    $outline-padding: 3px;

    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100%;
    height: 100%;
    transform: translateX(150%);

    @media not all and (prefers-reduced-motion) {
        transition: transform 250ms ease-in-out;
    }

    &--current {
        position: relative;
        transform: translateX(0);
    }

    &--done {
        position: absolute;
        transform: translateX(-150%);
    }

    &__header {
        @extend .container;

        --bs-gutter-x: #{spacing(5)};

        margin-bottom: calc(#{spacing(5)} - #{$outline-padding});
        text-align: center;

        & > :last-child {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: calc(#{spacing(7)} - #{$outline-padding});
        }
    }

    &__body {
        padding-top: $outline-padding;
        padding-bottom: spacing(6);

        & > .container {
            --bs-gutter-x: #{spacing(5)};
        }
    }

    &.wizard-feedback {
        justify-content: center;
        align-items: center;
        height: 100%;
        text-align: center;

        & > .container {
            --bs-gutter-x: #{spacing(5)};
        }

        & .btn {
            width: auto;
            padding-right: spacing(5);
            padding-left: spacing(5);
        }

        @include media-breakpoint-up(md) {
            & .btn {
                padding-right: spacing(6);
                padding-left: spacing(6);
            }
        }

        @include media-breakpoint-up(lg) {
            & p {
                padding-bottom: spacing(4);
            }
        }
    }
}

.wizard-footer {
    position: relative;
    min-height: 64px;
    background-color: $white;
    transform: translateY(0);

    @media not all and (prefers-reduced-motion) {
        transition: transform 125ms ease-in;
    }

    .wizard--complete & {
        transform: translateY(100%);
    }

    & .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }

    &__prev,
    &__next {
        flex: 1 1 20%;
        padding: spacing(3) 0;

        & .btn {
            width: auto;
            padding-right: spacing(4);
            padding-left: spacing(4);

            @include media-breakpoint-up(md) {
                padding-right: spacing(5);
                padding-left: spacing(5);
            }

            @media screen and (max-height: 500px) {
                padding: spacing(2) spacing(3);
            }
        }
    }

    &__next {
        text-align: right;

        .sm {
            display: none;
        }

        @include media-breakpoint-up(md) {
            .xs {
                display: none;
            }

            .sm {
                display: inline;
            }
        }
    }

    & .wizard-progress {
        content: "";
        position: absolute;
        top: -1px;
        left: -100%;
        width: 100%;
        height: 3px;
        background-color: $secondary;
        transform: translateX(0);
        transition: transform 250ms ease-in-out;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    &__steps {
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
            padding: spacing(5) 0;

            & .stepinator {
                display: flex;
                justify-content: center;
                gap: spacing(3);
                padding: 0;
                margin: 0;
                list-style: none;
            }

            & .stepinator__button {
                padding: spacing(2) spacing(3);
                color: $secondary;
                border-color: $secondary;
                box-shadow: inset 0 0 0 0 $secondary;
                transition: all 250ms ease-in-out;

                &:hover {
                    background: $bg-light;
                }

                &[disabled] {
                    background-color: $bg-light;
                    color: $secondary;
                    border-color: $bg-light;
                    opacity: 1;
                }

                &.stepinator__button--current {
                    color: $white;
                    border-color: $secondary;
                    box-shadow: inset 0 0 0 20px $secondary;
                }
            }
        }
    }
}
