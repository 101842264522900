//-- Preload
body::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    background: url("#{$static-path}/svgs/icon-loading-primary-color.svg");
}

//-- circle-arrow
.circle-arrow {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: $icon-button-wh;
    height: $icon-button-wh;
    border: 2px solid $primary;
    border-radius: spacing(4);
}

// Used as: .parent:hover & { @extend .circle-arrow-hover }
.circle-arrow-hover {
    border: none;
    background: linear-gradient(90deg, $active-gradient-start, $active-gradient-end);
    color: $white;
}

.transparent {
    opacity: 0;
}

//-- Create loading overlay
// @extend or use directly. Tested on primary & secondary buttons.
.with-loading-state {
    position: relative;

    &::before,
    &::after {
        content: "";
        position: absolute;
        pointer-events: none;
        opacity: 0;
        transition: opacity 150ms ease-out;
    }

    &.is--loading {
        z-index: 0;

        &::before,
        &::after {
            top: 1px;
            right: 1px;
            bottom: 1px;
            left: 1px;
            border-radius: spacing(6);
            opacity: 1;
        }

        &::before {
            z-index: 1;
            background: rgb(255 255 255 / 70%);
        }

        &::after {
            z-index: 2;
            background: url("#{$static-path}/svgs/icon-loading-primary-color.svg") no-repeat center;
        }
    }
}

// Used in teaser cards and on detail pages
.pagetype-pill {
    display: inline-block;
    padding: 0 spacing(2);
    background-color: rgb(240 243 255 / 100%);
    color: $secondary;
    font-size: 12px;
    font-style: normal;
    font-weight: $font-weight-bold;
    line-height: 28px;
    letter-spacing: 0.44px;
    border-radius: spacing(3);

    @include media-breakpoint-up(md) {
        padding: 0 spacing(3);
        font-size: 14px;
    }
}
