.pricing-block {
    $highlight-margin: spacing(6);

    &__header {
        display: flex;

        h2 {
            margin-bottom: 0;
        }

        & select {
            padding: 0 0 0 spacing(5);
            border: none;
            background-color: transparent;
            background-image: url("data:image/svg+xml;utf8,<svg viewbox='0 0 16 16' width='16' height='16' fill='hsl(15deg 100% 40%)' xmlns='http://www.w3.org/2000/svg'><path d='M7.293 10.536 4.464 7.707A1 1 0 0 1 5.88 6.293L8 8.414l2.121-2.121a1 1 0 1 1 1.415 1.414l-2.829 2.829a1 1 0 0 1-1.414 0Z' /></svg>");
            background-position: -12px center;
            background-size: 50px;
            background-repeat: no-repeat;
            color: $primary;
            font-weight: bold;
            appearance: none;
        }

        & option {
            font-size: 1rem;
        }

        @include media-breakpoint-up(lg) {
            & select {
                padding-left: spacing(6);
                background-position: 0 center;
            }
        }
    }

    &__list {
        display: flex;
        gap: spacing(4);
        flex-direction: column;

        & > * {
            flex: 0 1 33%;
        }

        --spacing-x: #{spacing(4)};

        @include media-breakpoint-up(md) {
            --spacing-x: #{spacing(5)};
        }

        // 2 or 3 products is the max we can show horizontally on LG & XL
        // to be consistent we show them vertically on XXL as well
        &:not(.pricing-block__list--vertical) {
            @include media-breakpoint-up(md) {
                --spacing-x: #{spacing(5)};
            }

            @include media-breakpoint-only(lg) {
                &[data-count="2"],
                &[data-count="3"] {
                    gap: spacing(2);
                }
            }

            @include media-breakpoint-up(lg) {
                flex-direction: row;

                &.pricing-block__list--with-highlighted-product {
                    padding-top: $highlight-margin;
                }

                &[data-count="2"],
                &[data-count="3"] {
                    --spacing-x: #{spacing(3)};
                }
            }

            @include media-breakpoint-only(xl) {
                &[data-count="2"],
                &[data-count="3"] {
                    gap: spacing(3);
                }
            }

            @include media-breakpoint-up(xl) {
                &[data-count="2"],
                &[data-count="3"] {
                    --spacing-x: #{spacing(4)};
                }
            }

            @include media-breakpoint-up(xxl) {
                gap: spacing(5);

                &,
                &[data-count="2"],
                &[data-count="3"] {
                    --spacing-x: #{spacing(5)};

                    gap: spacing(5);
                }
            }
        }
    }

    &-product {
        @extend .boxed-block;

        display: grid;
        flex-direction: unset;
        justify-content: unset;
        height: unset;
        padding: 0;

        @include media-breakpoint-up(md) {
            grid-template-columns: 33% auto;
            grid-template-rows: auto auto auto;
        }

        @include media-breakpoint-up(lg) {
            .pricing-block__list:not([class*="--vertical"]) & {
                grid-template-columns: 1fr;
                grid-template-rows: unset;
                grid-auto-rows: max-content;
            }

            .pricing-block__list--vertical & {
                grid-template-columns: 1fr max-content max-content;
                grid-template-rows: spacing(9) auto;
                /* stylelint-disable-next-line */
                grid-template-areas:
                    "name pricing actions"
                    "description description description";
                align-items: center;
            }
        }

        @include media-breakpoint-up(xl) {
            .pricing-block__list--vertical & {
                grid-template-columns: 1fr 1fr max-content;
            }
        }

        @include media-breakpoint-up(xxl) {
            .pricing-block__list--vertical & {
                grid-template-columns: 1fr 0.75fr max-content;
            }
        }

        &.highlight {
            border: 2px solid $secondary;
            margin-top: $highlight-margin;
            border-top-right-radius: 0;
            border-top-left-radius: 0;

            @include media-breakpoint-up(lg) {
                .pricing-block__list:not([class*="--vertical"]) & {
                    margin-top: 0;
                }
            }
        }

        &__highlight {
            position: absolute;
            top: calc(-#{$highlight-margin} + 1px);
            left: -2px;
            width: calc(100% + 4px);
            padding: 0.5rem 0;
            border: 2px solid $secondary;
            background-color: $secondary;
            color: white;
            font-size: 18px;
            text-align: center;
            border-radius: $border-radius $border-radius 0 0;
        }

        &__icon {
            position: absolute;
            top: 0;
            right: 0;
            width: 48px;
            height: 48px;
            margin: spacing(3) var(--spacing-x) 0;

            @include media-breakpoint-up(md) {
                margin-top: spacing(4);
            }

            @include media-breakpoint-up(lg) {
                .pricing-block__list:not([class*="--vertical"]) & {
                    position: unset;
                    margin: spacing(3) var(--spacing-x) 0;
                }

                .pricing-block__list--vertical & {
                    right: unset;
                    left: 0;
                    width: 64px;
                    height: 64px;
                    margin-top: spacing(5);
                }
            }
        }

        &__name {
            padding: spacing(4) var(--spacing-x);
            margin: 0;
            word-break: break-all;

            @include media-breakpoint-only(md) {
                grid-column: 1 / span 2;
                padding: spacing(6) var(--spacing-x);
            }

            @include media-breakpoint-up(lg) {
                .pricing-block__list--vertical & {
                    grid-area: name;
                    align-self: center;
                    padding: 0 var(--spacing-x);
                }

                .pricing-block__list--vertical .pricing-block-product__icon + & {
                    padding-left: spacing(9);
                }
            }
        }

        &__pricing {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: spacing(2);
            overflow: auto;
            padding: spacing(3) var(--spacing-x);
            color: $secondary;

            & strong {
                flex: 1 0 100%;
            }

            & .pricing-block-price__unit,
            & strong {
                position: sticky;
                left: 0;
            }

            @include media-breakpoint-up(md) {
                align-content: flex-start;
                padding-top: spacing(2);
                column-gap: spacing(2);

                & strong {
                    margin-bottom: spacing(1);
                }
            }

            @include media-breakpoint-up(lg) {
                .pricing-block__list:not([class*="--vertical"]) & {
                    flex-flow: column nowrap;
                    align-items: flex-start;
                    padding: 0 var(--spacing-x);

                    & strong {
                        flex: auto;
                    }
                }

                .pricing-block__list--vertical & {
                    grid-area: pricing;
                    flex-wrap: nowrap;
                    justify-self: start;
                    padding: 0;
                    font-size: 18px;

                    & strong {
                        flex: auto;
                        margin: 0 spacing(2) 0 0;
                    }
                }
            }
        }

        &__actions {
            padding: spacing(4) var(--spacing-x);
            border-bottom: 1px solid $border-color;

            @include media-breakpoint-down(md) {
                padding-top: 1rem;

                & .btn {
                    width: 100%;
                }
            }

            @include media-breakpoint-up(md) {
                grid-column: 1 / span 2;
                grid-row: -1;
                padding: spacing(5) var(--spacing-x);
                border-top: 1px solid $border-color;
                border-bottom: none;
            }

            @include media-breakpoint-up(lg) {
                .pricing-block__list:not([class*="--vertical"]) & {
                    grid-column: initial;
                    grid-row: initial;
                    padding: spacing(4) var(--spacing-x);
                    border-top: none;
                    border-bottom: 1px solid $border-color;

                    & .btn {
                        width: 100%;
                    }
                }

                .pricing-block__list--vertical & {
                    grid-area: actions;
                    padding-top: 0;
                    padding-bottom: 0;
                    border-top: none;
                }
            }

            @include media-breakpoint-up(xl) {
                & .btn {
                    padding: spacing(3) calc(spacing(4) + 0.5rem);
                }
            }
        }

        &__description {
            display: flex;
            flex-direction: column;
            padding: spacing(4) var(--spacing-x);

            & *:last-child {
                margin-bottom: 0;
            }

            @include media-breakpoint-only(md) {
                padding: spacing(2) var(--spacing-x) spacing(5);
                border-bottom: none;
            }

            @include media-breakpoint-up(lg) {
                .pricing-block__list:not([class*="--vertical"]) & {
                    padding-bottom: spacing(5);

                    p + .checkbox-list {
                        padding: spacing(4) var(--spacing-x) 0 var(--spacing-x);
                        border-top: 1px solid $border-color;
                        margin: 0 calc(var(--spacing-x) * -1);
                    }
                }

                .pricing-block__list--vertical & {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-column-gap: spacing(5);
                    grid-area: description;
                    border-top: 1px solid $border-color;

                    p {
                        grid-column: 1;
                    }

                    .checkbox-list {
                        grid-column: 2;
                        grid-row: 1;
                    }
                }
            }
        }
    }
}

.pricing-block-price {
    display: inline-block;
    padding: 0 1rem;
    background-color: rgb(240 243 255 / 100%);
    color: $secondary;
    border-radius: 1.5rem;
    font-size: 32px;
    letter-spacing: 0.67px;

    &__value {
        font-weight: bold;
    }

    @include media-breakpoint-up(lg) {
        .pricing-block__list--vertical & {
            &__currency {
                font-weight: $font-weight-base;
            }
        }
    }
}

// Stronger selector to prevent !important
.pricing-block__disclaimer.rich-text {
    margin-bottom: 0;
}
