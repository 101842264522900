.error-page {
    padding: calc(#{$header-height-xs} + #{spacing(6)}) 0 spacing(6);
    background-color: $bg-light;
    color: $secondary;
    font-weight: $font-weight-bold;
    text-align: center;

    h1 {
        margin-bottom: 0;
        font-size: 88px;
    }

    p {
        margin-bottom: spacing(4);
        font-size: 40px;
    }

    @include media-breakpoint-up(sm) {
        padding: calc(#{$header-top-sm} + #{$header-height-sm} + #{spacing(7)}) 0 spacing(7);
    }

    @include media-breakpoint-up(md) {
        p {
            margin-bottom: spacing(5);
        }
    }

    @include media-breakpoint-up(lg) {
        padding: calc(#{$header-top-lg} + #{$header-height-lg} + #{spacing(9)}) 0 spacing(9);
    }
}
