.button-list {
    display: flex;
    flex-flow: column wrap;
    gap: spacing(3);
    @include block-bottom-margin;

    & > .btn-tertiary {
        justify-content: flex-start;
    }

    @include media-breakpoint-up(sm) {
        flex-direction: row;
    }
}
