//-- boxed-block
// Can be used without illustration top-right
.boxed-blocks-container {
    & > h2 {
        padding-bottom: spacing(3);
        text-align: center;

        @include media-breakpoint-up(md) {
            padding-bottom: spacing(4);
        }
    }
}

.boxed-blocks-row {
    @extend .row;
    @extend .gx-lg-5;
    @extend .gy-3;
    @extend .gy-md-4;

    justify-content: center;
}

.boxed-blocks-col {
    @extend .col-12;
    @extend .col-lg-4;
}

.boxed-block {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: spacing(5) spacing(4);
    border: 1px solid $border-color;
    background-color: $white;
    border-radius: $border-radius;
    box-shadow:
        0 24px 80px rgb(0 0 0 / 4%),
        0 2px 8px rgb(0 0 0 / 7.72%);

    @include media-breakpoint-up(md) {
        padding: 2.5rem;
    }

    @include media-breakpoint-up(lg) {
        padding: spacing(5);
    }

    &--with-illustration .boxed-block__body {
        position: relative;
        z-index: 1;
        margin-right: spacing(6);

        & > h3 {
            margin: 0 spacing(3) spacing(3) 0;
        }

        @include media-breakpoint-up(xl) {
            margin-right: spacing(6);
        }
    }
}

a.boxed-block:hover {
    box-shadow: rgb(0 0 0 / 8%) 0 16px 24px 0;
}

.boxed-block__illustration {
    position: absolute;
    top: 0;
    right: 0;
    padding: spacing(2) spacing(2) spacing(5) spacing(5);
    border-top-right-radius: $border-radius;
    clip-path: ellipse(74% 112% at 73% -26%);
    aspect-ratio: 1/1;
    background-color: $bg-light;

    & svg {
        width: 60px;
        height: 60px;

        @include media-breakpoint-only(md) {
            width: 70px;
            height: 70px;
        }
        @include media-breakpoint-only(xxl) {
            width: 70px;
            height: 70px;
        }
    }
}
