.modal-full {
    position: fixed;
    z-index: $zindex-modal;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100vw;
    height: 100%;
    max-height: 100vh;
    padding: 0;
    border: none;
    margin: 0;
    background: transparent;
}

.modal-full-body {
    overflow: scroll;
    width: 100%;
    height: 100%;
    background: $white;
    transform: scale(0);

    @media not all and (prefers-reduced-motion) {
        // Match transition-duration in JS
        transition: transform var(--default-animation-speed) ease-in-out;
    }

    .modal--open & {
        transform: scale(1);
    }
}

//-- Start elements created by JS

// To hide stuttering on IOS when body-scroll-lock activates
// Not using ::backdrop because that only dipslays when modal.showModal() is called
.ios-backdrop {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $white;
}

.modal__loading {
    position: fixed;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    width: 100px;
    height: 100px;
}
//-- End elements created by JS
