.fader {
    position: relative;
    display: grid;

    & > * {
        // This creates an area equal to the biggest slide to avoid a content shift.
        grid-area: 1 / 1 / 2 / 2;

        // We hide all slides, except the active one;
        opacity: 0;
        transition: opacity 1s ease;

        &.active {
            opacity: 1;
        }
    }
}
