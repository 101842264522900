// Extends bootstrap default styling to match our own
@mixin form-control-focus {
    &:focus,
    &:focus-within:not(.is-invalid) {
        border-color: $focus-color;
    }

    &:focus,
    &:focus-within,
    &.is-invalid:focus {
        box-shadow:
            0 0 0 0.25rem rgb(0 0 0 / 8%),
            inset 2px 2px 4px 0 rgb(0 0 0 / 8%);
    }
}

.open-floating-form {
    position: fixed;
    top: 84px;
    right: 0;
    transform: translateX(-100vw);
    transition:
        transform 550ms ease-in-out,
        opacity 350ms ease-in-out;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    opacity: 0.7;
    width: fit-content;

    @include media-breakpoint-only(xs) {
        padding: spacing(2);
    }

    @include media-breakpoint-up(lg) {
        top: 0;
        opacity: 0.4;
    }

    @media (min-width: #{map-get($grid-breakpoints, 'lg')}) and (max-height: 666px) {
        top: 65px;
    }

    &:hover {
        opacity: 1;
    }

    &.hidden {
        transform: translateX(100vw);
        transition: transform 250ms ease-in-out;
    }
}

.floating-form {
    position: fixed;
    z-index: 1040;
    right: 0;
    bottom: 0;
    display: flex;
    max-width: 100vw;
    height: 100dvh;
    padding: spacing(3);
    background-color: white;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    transform: translateX(100vw);
    visibility: visible;
    transition:
        transform 500ms ease-in-out,
        opacity 50ms ease-in-out;

    @include media-breakpoint-up(md) {
        bottom: 1rem;
        height: fit-content;
        border: 1px solid rgb(25 52 148 / 50%);
        background-color: rgb(255 255 255 / 80%);
    }

    &.open {
        transform: translateX(0);
    }

    &.hidden {
        visibility: hidden;
    }

    .contact {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        width: 100vw;
        height: fit-content;
        max-height: calc(100vh - 2rem);

        @include media-breakpoint-up(md) {
            width: 450px;
            max-height: calc(100vh - 204px);
        }
    }

    .form-field {
        position: relative;
        margin-bottom: 0;

        & .is-invalid ~ .invalid-feedback,
        &__help {
            font-size: 0.875rem;
        }

        &:only-child {
            grid-column: span 2;
        }

        &.simple-select {
            grid-column: span 2;
        }

        .checkbox-block {
            border: none;
            background-color: transparent;
            box-shadow: none;

            .rich-text a span {
                background-color: transparent;
            }
        }
    }

    .form-control,
    .form-select {
        @include media-breakpoint-only(xs) {
            border-radius: $input-border-radius-sm;
        }

        padding: spacing(2) spacing(3);
        border-width: 2px;
        font-size: 1rem;
        letter-spacing: 0.5px;

        @include form-control-focus;

        &::placeholder {
            opacity: 0.6;
        }
    }

    .form-control {
        box-shadow: inset 2px 2px 4px 0 rgb(0 0 0 / 8%);
    }

    .form-select {
        background-color: $gray-100;
    }

    .text-field {
        display: flex;
        overflow: hidden;
        padding: 0;

        @include form-control-focus;

        &__input {
            display: block;
            flex: 1 0;
            min-width: 0;
            padding: $input-padding-y $input-padding-x;
            border: none;
            background: $white;
            font-size: 1rem;
            letter-spacing: 0.5px;
            border-top-left-radius: $input-border-radius;
            border-bottom-left-radius: $input-border-radius;
            // Against autofill background
            box-shadow:
                inset 2px 2px 4px 0 rgb(0 0 0 / 8%),
                inset 0 0 0 30px white;

            &:focus-visible {
                outline: none;
            }

            &::placeholder {
                opacity: 0.6;
            }
        }

        &__label {
            display: none;
            opacity: 0;
        }

        &__input:placeholder-shown {
            flex: 1 0 100%;
        }

        &__input:placeholder-shown + &__label {
            opacity: 0;
            transform: translateX(-100vw);
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        appearance: none;
    }

    .checkbox-block {
        display: flex;
        gap: 0.5rem;
    }

    .checkbox-field {
        &__label {
            font-size: 1rem;
        }
    }

    .contact-form-section {
        h3 {
            font-size: 18px;
        }

        h3:not(:first-of-type) {
            margin-top: spacing(2);
        }

        &__footer {
            display: flex;
            flex-direction: column;
            margin-top: 0.5rem;
            gap: 0.5rem;
        }
    }

    .fieldset-grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 0.4rem;
    }

    .close-btn {
        position: relative;
        align-self: flex-end;
        width: 1.5rem;
        height: 1.5rem;
        background-color: $_spring-blue;
        color: white;
        border-radius: 50%;

        &:hover {
            background-color: color-mix(in srgb, $_spring-blue 80%, white);
        }

        @include media-breakpoint-up(md) {
            position: absolute;
            top: -15px;
            left: -15px;
            width: 2rem;
            height: 2rem;
        }
    }
}
