.slider {
    // full width slider
    position: relative;
    display: flex;
    overflow-x: scroll;
    width: 100vw;
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
    scroll-padding-left: 10%; // this is overwritten in JS. We cannot use css-calc for this padding.
    &::-webkit-scrollbar {
        display: none;
    }

    & > * {
        position: relative;
        scroll-snap-align: start;
        scroll-snap-stop: normal;
    }
}
