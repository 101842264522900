.testimonials {
    position: relative;

    & h2 {
        margin-bottom: spacing(4);

        @include media-breakpoint-up(md) {
            padding-top: 10px;
            padding-right: 12rem;
            margin-bottom: spacing(6);
        }
    }

    &__navigation {
        gap: 1rem;
        display: flex;

        @include media-breakpoint-up(md) {
            position: absolute;
            top: 0;
            right: 0;
        }

        & button {
            @include media-breakpoint-down(md) {
                position: absolute;
                z-index: 2;
                top: 8rem;
                width: 3rem;
                height: 3rem;
                border: none;
                background-color: white;
            }

            &:first-child {
                left: 1rem;
            }

            &:last-child {
                right: 1rem;
            }
        }
    }

    &__slider {
        position: relative;

        @include media-breakpoint-up(md) {
            position: unset;
        }
    }

    &__slides {
        scroll-padding-left: 12px !important;
    }

    & figure {
        position: relative;
        width: 100%;
        padding-right: 3rem;

        @include media-breakpoint-up(md) {
            width: unset;
            padding-right: 7.5rem;
        }

        @include media-breakpoint-up(lg) {
            padding-right: 0;
            margin-bottom: 5rem;
        }
    }

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left;
    }

    &__photo {
        position: relative;
        display: block;
        overflow: hidden;
        height: 260px;
        border-top-left-radius: 0.5rem;
        @include media-breakpoint-up(md) {
            height: 400px;
        }

        @include media-breakpoint-up(lg) {
            top: -4rem;
            left: -4rem;
            width: 480px;
            height: 560px;
            border-radius: 0.5rem;
        }
    }

    &__logo {
        position: absolute;
        right: 1.5rem;
        bottom: -1.5rem;
        overflow: hidden;
        width: 152px;
        border-radius: 0.5rem;

        @include media-breakpoint-up(md) {
            right: 3.75rem;
            bottom: -3rem;
            width: 272px;
            height: 196px;
        }

        @include media-breakpoint-up(lg) {
            right: unset;
            bottom: -1.5rem;
            left: -8rem;
            width: 309px;
        }
    }

    &__testimonial {
        border-radius: 0.5rem;
        background-color: $bg-light-alt;

        @include media-breakpoint-up(lg) {
            display: flex;
            margin-top: 6rem;
            margin-left: 8rem;
        }

        @include media-breakpoint-up(xxl) {
            gap: 2rem;
        }
    }

    &__body {
        padding: 1.5rem;

        @include media-breakpoint-up(md) {
            padding: 3.5rem;
        }

        @include media-breakpoint-up(lg) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 0;
        }
    }

    & blockquote {
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 30px;
        letter-spacing: 0.6px;

        @include media-breakpoint-up(md) {
            font-size: 24px;
            line-height: 2.5rem;
        }

        &::before {
            content: "";
            display: block;
            width: 38px;
            height: 38px;
            margin-bottom: 1rem;
            background: url("/static/spring/svgs/icon-quote.svg") no-repeat center;
            background-size: 38px;

            @include media-breakpoint-up(md) {
                width: 55px;
                height: 55px;
                margin-bottom: 2rem;
                background-size: 55px;
            }

            @include media-breakpoint-up(lg) {
                margin-bottom: 3.5rem;
            }
        }
    }

    & cite {
        margin-top: 1rem;
        color: #2f2f2f;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px;
        letter-spacing: 0.58px;

        @include media-breakpoint-up(md) {
            margin-top: 2.5rem;
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0.63px;
        }

        &::before {
            content: "";
            display: block;
            display: inline-block;
            width: 24px;
            height: 2px;
            margin-right: 0.5rem;
            margin-bottom: 0.25rem;
            background-color: $primary;
        }
    }
}
