.multiple-links {
    h2 {
        margin-bottom: spacing(5);
    }

    h3 {
        margin-bottom: spacing(3);
    }

    .multiple-links__items {
        display: flex;
        flex-direction: column;
        gap: spacing(2);
    }

    & + & {
        margin-top: spacing(5);
    }

    @include media-breakpoint-up(md) {
        h2 {
            margin-bottom: spacing(6);
        }

        & + & {
            margin-top: spacing(6);
        }

        .multiple-links__items {
            flex-direction: row;
            justify-content: space-between;
            gap: spacing(5);
        }

        .multiple-links-item {
            display: flex;
            flex: 0 1 50%;
            flex-direction: column;
            justify-content: space-between;
        }

        [data-items="3"] .multiple-links-item {
            flex: 0 1 33.33%;
        }
    }

    @include media-breakpoint-up(lg) {
        h2 {
            margin-bottom: spacing(7);
        }

        & + & {
            margin-top: spacing(7);
        }
    }
}
