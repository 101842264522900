// Extends bootstrap default styling to match our own
@mixin form-control-focus {
    &:focus,
    &:focus-within:not(.is-invalid) {
        border-color: $focus-color;
    }

    &:focus,
    &:focus-within,
    &.is-invalid:focus {
        box-shadow:
            0 0 0 0.25rem rgb(0 0 0 / 8%),
            inset 2px 2px 4px 0 rgb(0 0 0 / 8%);
    }
}

.form-field {
    position: relative;
    margin-bottom: spacing(3);

    & .is-invalid ~ .invalid-feedback,
    &__help {
        font-size: 0.875rem;

        @include media-breakpoint-up(md) {
            font-size: 1.125rem;
        }
    }
}

.form-control,
.form-select {
    @include media-breakpoint-only(xs) {
        border-radius: $input-border-radius-sm;
    }

    padding: spacing(2) spacing(3);
    border-width: 2px;
    letter-spacing: 0.5px;

    @include form-control-focus;

    @include media-breakpoint-up(md) {
        padding: 0.75rem 1.25rem;
        font-size: 1.125rem;
    }

    &::placeholder {
        opacity: 0.6;
    }
}

.form-control {
    box-shadow: inset 2px 2px 4px 0 rgb(0 0 0 / 8%);
}

.form-select {
    background-color: $gray-100;
}

.text-field {
    display: flex;
    overflow: hidden;
    padding: 0;

    @include form-control-focus;

    &__input {
        display: block;
        flex: 1 0;
        min-width: 0;
        padding: $input-padding-y $input-padding-x;
        border: none;
        background: $white;
        font-size: 1rem;
        letter-spacing: 0.5px;
        border-top-left-radius: $input-border-radius;
        border-bottom-left-radius: $input-border-radius;
        // Against autofill background
        box-shadow:
            inset 2px 2px 4px 0 rgb(0 0 0 / 8%),
            inset 0 0 0 30px white;

        &:focus-visible {
            outline: none;
        }

        &::placeholder {
            opacity: 0.6;
        }

        @include media-breakpoint-up(md) {
            padding: 0.75rem 1.25rem;
            font-size: 1.125rem;
        }
    }

    &__label {
        display: flex;
        align-items: center;
        min-width: 0;
        max-width: 80%;
        padding: 3px spacing(3) 3px 0;
        color: $secondary;
        font-size: 0.875rem;
        font-weight: bold;
        white-space: nowrap;
        border-top-right-radius: $input-border-radius;
        border-bottom-right-radius: $input-border-radius;
        transform: translateX(0);
        opacity: 1;

        @media not all and (prefers-reduced-motion) {
            transition:
                transform 180ms ease-out,
                opacity 280ms ease-in;
        }

        @include media-breakpoint-up(md) {
            font-size: 1rem;
        }
    }

    &__input:placeholder-shown {
        flex: 1 0 100%;
    }

    &__input:placeholder-shown + &__label {
        opacity: 0;
        transform: translateX(-100vw);
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    appearance: none;
}
