//-- Abstracts
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.debug {
    visibility: hidden;
    position: fixed;
    z-index: 9999999;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 35px;
    background-color: yellowgreen;
    text-align: center;

    &::before {
        content: "xs";

        @include media-breakpoint-up(sm) {
            content: "sm";
        }

        @include media-breakpoint-up(md) {
            content: "md";
        }

        @include media-breakpoint-up(lg) {
            content: "lg";
        }

        @include media-breakpoint-up(xl) {
            content: "xl";
        }

        @include media-breakpoint-up(xxl) {
            content: "xxl";
        }
    }
}
