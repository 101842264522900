.comparison {
    @extend .boxed-blocks-container;
}

@include media-breakpoint-up(lg) {
    .comparison .boxed-blocks-row {
        justify-content: flex-start;
    }

    .comparison[data-items="2"] .boxed-blocks-col,
    .comparison[data-items="4"] .boxed-blocks-col {
        width: 50%;
    }
}

.comparison-item__buttons {
    @extend .button-list;

    margin-top: spacing(3);
    margin-bottom: 0;

    & > .btn-tertiary {
        justify-content: center;
    }

    & > .btn-tertiary:only-child {
        justify-content: flex-start;
    }

    @include media-breakpoint-up(lg) {
        .comparison:not([data-items="2"]),
        .comparison:not([data-items="4"]) {
            & > .btn {
                flex: 1 0 100%;
            }
        }
    }
}
